import React, { ReactElement, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { StripeAPI } from '../../api';

type CheckoutReturnParams = {
  checkoutSessionId: string;
};

const AnalyzerCheckoutReturn = (): ReactElement => {
  const history = useHistory();

  const params = useParams<CheckoutReturnParams>();

  const { data, isSuccess } = StripeAPI.endpoints.getCheckoutSession.useQuery(
    params.checkoutSessionId,
  );

  const [updateSubscriptionDetails] =
    StripeAPI.endpoints.updateSubscriptionDetails.useMutation();

  useEffect(() => {
    if (isSuccess && data?.status === 'complete' && data?.subscriptionId.length > 0) {
      void updateSubscriptionDetails(data.subscriptionId);
    }
  }, [isSuccess, data]);

  if (data?.status === 'open') {
    history.push('/analyzer/checkout');
  }

  if (isSuccess && data?.status === 'complete') {
    return (
      <div className="checkout-overlay">
        <h2 className="settings-header">Subscription Complete!</h2>
        <section id="success">
          <p>
            We appreciate your business! A confirmation email will be sent to{' '}
            <strong>{data.customerEmail}</strong>. If you have any questions, please
            email <a href="mailto:support@pluralpolicy.com">support@pluralpolicy.com</a>
            .
          </p>
          <p>
            <Link to="/analyzer/search/">Click here to start analyzing bills</Link>
          </p>
        </section>
      </div>
    );
  }

  return <></>;
};

export default AnalyzerCheckoutReturn;
