import React, { ReactElement, useEffect } from 'react';
import './Analyzer.scss';
import { Route, Switch } from 'react-router-dom';
import AnalyzerLandingPageLayout from './AnalyzerLandingPageLayout';
import AnalyzerLoggedInLayout from './AnalyzerLoggedInLayout';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
import AnalyzerLogInOverlay from './AnalyzerLogInOverlay';
import AnalyzerSearchAndSummaryPage from './AnalyzerSearchAndSummaryPage';
import AnalyzerAccountSettings from './AnalyzerAccountSettings';
import { getOrganizationUser } from '../../dux/AccountDux';
import AnalyzerCheckout from './AnalyzerCheckout';
import AnalyzerCheckoutReturn from './AnalyzerCheckoutReturn';
import AnalyzerSubscriptionOverlay from './AnalyzerSubscriptionOverlay';
import { StripeAPI } from '../../api';

const RequireLogin: React.FC<{ children: ReactElement }> = ({ children }) => {
  const authToken = useSelector((state: State) => state.authentication.authToken);

  if (!authToken) {
    return <AnalyzerLogInOverlay />;
  }

  return <AnalyzerLoggedInLayout>{children}</AnalyzerLoggedInLayout>;
};

const RequireSubscription: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { data: userSubscription } =
    StripeAPI.endpoints.getSubscriptionForUser.useQuery();

  if (!userSubscription || userSubscription.status !== 'active') {
    return <AnalyzerSubscriptionOverlay />;
  }

  return children;
};

const AnalyzerRootLayout = (): ReactElement => {
  const authToken = useSelector((state: State) => state.authentication.authToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(getOrganizationUser());
    }
  }, [dispatch, authToken]);

  // Terrible hack to hide the intercom controls from showing up on any analyzer page.
  // Maybe there's a way to selectively hide this within the Intercom app?
  const styleHack = `
    .intercom-lightweight-app {
        display: none;
    }
  `;

  return (
    <>
      <style>{styleHack}</style>
      <div className="analyzer">
        <Switch>
          <Route path="/analyzer/summary/:billId?">
            <RequireLogin>
              <RequireSubscription>
                <AnalyzerSearchAndSummaryPage />
              </RequireSubscription>
            </RequireLogin>
          </Route>
          <Route path="/analyzer/settings">
            <RequireLogin>
              <AnalyzerAccountSettings />
            </RequireLogin>
          </Route>
          <Route path="/analyzer/checkout">
            <RequireLogin>
              <AnalyzerCheckout />
            </RequireLogin>
          </Route>
          <Route path="/analyzer/return/:checkoutSessionId">
            <RequireLogin>
              <AnalyzerCheckoutReturn />
            </RequireLogin>
          </Route>
          <Route path="/analyzer/login">
            <AnalyzerLogInOverlay />
          </Route>
          <Route path="/analyzer">
            <AnalyzerLandingPageLayout />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default AnalyzerRootLayout;
