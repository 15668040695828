import { BillSearchCriteria, SavedSearch } from '@enview/interface/types/BillSearch';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Team, TeamMembership, TeamRole } from '@enview/interface/types/Team';
import * as Analytics from '../analytics/TeamAnalytics';
import { State } from '../dux/@types';
// eslint-disable-next-line import/no-cycle
import { setTeamMode } from '../dux/TeamModeDux';
import base, { ApiTag } from './base';

const teamAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getUsersTeamMemberships: build.query<TeamMembership[], void>({
      query: () => ({ url: `/teams`, method: 'GET' }),
      providesTags: (result) => (result ? [{ type: ApiTag.TEAM_TAG }] : []),
      extraOptions: { requiresAuth: true },
    }),
    getTeamMembers: build.query<TeamMembership[], number>({
      query: (teamId) => ({ url: `/teams/${teamId}/users`, method: 'GET' }),
      providesTags: (result, error, teamId) =>
        result
          ? [
              { type: ApiTag.TEAM_TAG, id: teamId },
              ...result.map(({ organizationUserId }) => ({
                type: ApiTag.ORGANIZATION_USER_TAG,
                id: organizationUserId,
              })),
            ]
          : [],
      extraOptions: { requiresAuth: true },
    }),
    getPendingTeamMembers: build.query<Partial<TeamMembership>[], number>({
      query: (teamId) => ({
        url: `/teams/${teamId}/invitees?status=pending`,
        method: 'GET',
      }),
      providesTags: (result, error, teamId) =>
        result ? [{ type: ApiTag.TEAM_TAG, id: teamId }] : [],
    }),
    cancelInvite: build.mutation<void, { teamId: number; email: string }>({
      query: ({ teamId, email }) => ({
        url: `/teams/${teamId}/cancel-invite`,
        method: 'DELETE',
        data: { email },
      }),
      invalidatesTags: (response, error, { teamId }) => [
        { type: ApiTag.TEAM_TAG, id: teamId },
      ],
    }),
    inviteToTeam: build.mutation<
      any,
      { teamId: number; email: string; role: TeamRole; refresh?: boolean }
    >({
      query: ({ teamId, email, role, refresh }) => ({
        url: `/teams/${teamId}/invite${refresh ? '?refresh=true' : ''}`,
        method: 'POST',
        data: { email, role },
      }),
      onQueryStarted: ({ email, role }) => {
        Analytics.trackInviteUser(true, email, role);
      },
      invalidatesTags: (result, error, { teamId }) => [
        { type: ApiTag.TEAM_TAG, id: teamId },
      ],
    }),
    leaveTeam: build.mutation<void, { teamId: number; userId: number }>({
      query: ({ teamId, userId }) => ({
        url: `/teams/${teamId}/leave/user/${userId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, { getState, dispatch }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        if (!orgUser) return;
        dispatch(setTeamMode(orgUser.teamId));
      },
      invalidatesTags: (result, error, { teamId }) => [
        { type: ApiTag.TEAM_TAG, id: teamId },
      ],
    }),
    updateTeamRoleForUser: build.mutation<
      void,
      { teamId: number; userId: number; userRole: string }
    >({
      query: ({ teamId, userId, userRole }) => ({
        url: `/teams/${teamId}/user/${userId}`,
        method: 'PUT',
        data: { role: userRole },
      }),
      onQueryStarted: ({ teamId, userId, userRole }) => {
        Analytics.trackWorkspaceUserUpdated(userId, teamId, userRole);
      },
      invalidatesTags: (response, error, { teamId, userId }) => [
        { type: ApiTag.TEAM_TAG, id: teamId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: userId },
      ],
    }),
    deleteUserFromTeam: build.mutation<void, { teamId: number; userId: number }>({
      query: ({ teamId, userId }) => ({
        url: `/teams/${teamId}/user/${userId}`,
        method: 'DELETE',
      }),
      onQueryStarted: ({ teamId, userId }) => {
        Analytics.trackWorkspaceUserRemoved(userId, teamId);
      },
      invalidatesTags: (response, error, { teamId, userId }) => [
        { type: ApiTag.TEAM_TAG, id: teamId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: userId },
      ],
    }),
    createTeam: build.mutation<Team, Partial<Team>>({
      query: (team) => ({
        url: `teams`,
        method: 'POST',
        data: team,
      }),
      invalidatesTags: (_result) => [{ type: ApiTag.TEAM_TAG }],
      onQueryStarted: (team, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        if (!orgUser) return;
        Analytics.trackWorkspaceCreated(team, orgUser);
      },
    }),
    editTeam: build.mutation<void, Partial<Team>>({
      query: (team) => ({
        url: `/teams/${team.id}`,
        method: 'PUT',
        data: {
          name: team.name,
          isExternal: team.isExternal,
          ...(team.description
            ? { description: team.description }
            : { description: '' }),
        },
      }),
      onQueryStarted: (team, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        if (!orgUser) return;
        Analytics.trackWorkspaceEdited(team, orgUser);
      },
      invalidatesTags: () => [{ type: ApiTag.TEAM_TAG }],
    }),
    deleteTeam: build.mutation<void, number>({
      query: (teamId) => ({
        url: `/teams/${teamId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (teamId, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        if (!orgUser) return;
        Analytics.trackWorkspaceDeleted(teamId, orgUser);
      },
      invalidatesTags: () => [{ type: ApiTag.TEAM_TAG }],
    }),
    getOrganizationsTeams: build.query<Team[], number>({
      query: (orgId) => ({ url: `/teams/org/${orgId}`, method: 'GET' }),
      providesTags: (result, error, orgId) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: ApiTag.TEAM_TAG, id }) as const),
              { type: ApiTag.ORGANIZATION_TAG, id: orgId },
            ]
          : [{ type: ApiTag.ORGANIZATION_TAG, id: orgId }],
    }),
    createSavedSearch: build.mutation<
      SavedSearch,
      { teamId: number; name: string; criteria: Partial<BillSearchCriteria> }
    >({
      query: (params) => ({
        url: `/teams/${params.teamId}/saved-search`,
        method: 'POST',
        data: { name: params.name, criteria: params.criteria },
      }),
      invalidatesTags: (result, error, { teamId }) => [
        { type: ApiTag.SAVED_SEARCHES_TAG, id: teamId },
      ],
    }),
    editSavedSearch: build.mutation<void, Partial<SavedSearch>>({
      query: (savedSearch) => ({
        url: `/teams/${savedSearch.teamId}/saved-search/${savedSearch.id}`,
        method: 'PUT',
        data: {
          ...(savedSearch.name ? { name: savedSearch.name } : {}),
          ...(savedSearch.searchCriteria
            ? { searchCriteria: savedSearch.searchCriteria }
            : {}),
          ...(savedSearch.isArchived ? { isArchived: savedSearch.isArchived } : {}),
        },
      }),
      onQueryStarted: (savedSearch, { getState }) => {
        const orgUser = (getState() as unknown as State).account.organizationUser;
        if (!orgUser || !savedSearch.teamId) return;
        Analytics.trackSavedSearchUpdated(orgUser, savedSearch.teamId);
      },
      invalidatesTags: (result, error, { teamId }) => [
        { type: ApiTag.SAVED_SEARCHES_TAG, id: teamId },
      ],
    }),
    getSavedSearches: build.query<SavedSearch[], number>({
      query: (teamId) => ({ url: `/teams/${teamId}/saved-searches`, method: 'GET' }),
      transformResponse: (resp: Array<SavedSearch>) =>
        resp.map((savedSearch: SavedSearch) => {
          return {
            ...savedSearch,
            searchCriteria: {
              ...savedSearch.searchCriteria,
              actionFilter:
                savedSearch.searchCriteria.actionFilter?.fromDate &&
                savedSearch.searchCriteria.actionFilter?.toDate
                  ? {
                      ...savedSearch.searchCriteria.actionFilter,
                      fromDate: new Date(
                        savedSearch.searchCriteria.actionFilter.fromDate,
                      ),
                      toDate: new Date(savedSearch.searchCriteria.actionFilter.toDate),
                    }
                  : undefined,
            },
          };
        }),
      providesTags: (result, error, teamId) => [
        { type: ApiTag.SAVED_SEARCHES_TAG, id: teamId },
      ],
    }),

    updateSavedSearchAlerts: build.mutation<
      void,
      {
        teamId: number;
        savedSearchId: string;
        orgUser: OrganizationUser;
        savedSearchUserSubscriptionUpdate: {
          isSubscribed?: boolean;
          isFavorited?: boolean;
        };
      }
    >({
      query: (params) => ({
        url: `/teams/${params.teamId}/saved-searches/${params.savedSearchId}/subscriptions/${params.orgUser.id}`,
        method: 'PUT',
        data: {
          savedSearchUserSubscriptionUpdate: params.savedSearchUserSubscriptionUpdate,
        },
      }),
      onQueryStarted: (data) => {
        Analytics.trackSavedSearchSubscriptionUpdated(
          data.orgUser,
          data.teamId,
          data.savedSearchId,
          data.savedSearchUserSubscriptionUpdate,
        );
      },
      invalidatesTags: (result, error, params) => [
        { type: ApiTag.SAVED_SEARCHES_TAG, id: params.teamId },
      ],
    }),

    runSavedSearch: build.mutation<
      void,
      {
        teamId: number;
        savedSearchId: string;
        userId: number;
      }
    >({
      query: (params) => ({
        url: `/teams/${params.teamId}/saved-searches/${params.savedSearchId}/subscriptions/${params.userId}/results`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, params) => [
        { type: ApiTag.SAVED_SEARCHES_TAG, id: params.teamId },
      ],
    }),
  }),
  overrideExisting: false,
});
export default teamAPI;
