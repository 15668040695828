import React, { ReactElement, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BillAPI } from '../../api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import AnalyzerLoadingAnimation from './AnalyzerLoadingAnimation';

type AnalyzerSearchUrlParams = {
  jurisdictionAbbr: string;
  searchText: string;
};

const AnalyzerSearchResults = (): ReactElement => {
  const params = useParams<AnalyzerSearchUrlParams>();
  const { jurisdictionAbbr, searchText } = params;

  const searchResultsRef = useRef<HTMLDivElement>(null);

  const {
    data: searchResults,
    isSuccess,
    isFetching,
    isError,
  } = BillAPI.endpoints.searchBillsByBillNumber.useQuery(
    jurisdictionAbbr.length > 0 && searchText.length > 0
      ? {
          searchText,
          jurisdictionAbbr,
        }
      : skipToken,
  );

  useEffect(() => {
    searchResultsRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [searchResults]);

  return (
    <>
      <div className="search-results" ref={searchResultsRef}>
        {isError && (
          <div className="error m-2">
            Error loading search results. Reloading the page may help.
          </div>
        )}
        {isFetching && <AnalyzerLoadingAnimation message="Loading search results" />}
        {isSuccess && !isFetching && (
          <>
            {searchResults.data.length === 0 && (
              <div className="no-bill-results my-2">
                No results. Please try a different search.
              </div>
            )}
            {searchResults.data.map((b) => (
              <Link key={b.id} to={`/analyzer/summary/${b.id}`}>
                <div className="bill-card" key={b.id}>
                  <span className="number">
                    {b.session.state.abbreviation.toUpperCase()} {b.number}
                  </span>
                  <span className="name">{b.name}</span>
                  <span className="status pill dark-green">
                    {b.status ? b.status.replace(/_/g, ' ') : 'unavailale'}
                  </span>
                  <span className="session">{b.session.displayName}</span>
                </div>
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default AnalyzerSearchResults;
