import { ReactElement } from 'react';
import { SortOrder } from '../../../models/SortOrder';
import BillViewDropdown from '../DropDowns/BillViewDropdown';
import MultipleSelectDropDown, {
  OptionType,
} from '../DropDowns/MultipleSelectDropDown';
import SortDropdown from '../DropDowns/SortDropdown';

type SortAndFilterProps = {
  totalBills?: number;
  filterLabel?: string;
  selectedFilters?: string[];
  filterOptions?: OptionType<string>[];
  tagFilterOptions?: OptionType<string>[];
  selectedTags?: string[];
  setSelectedTags?: (tags: string[]) => void;
  setSelectedFilters?: (selection: string[]) => void;
  sortOrder: SortOrder;
  sortOptions: SortOrder[];
  setSortOrder: (selection: SortOrder) => void;
  actionButton?: ReactElement;
  showViewDropdown?: boolean;
};

const SortAndFilterHeader = (props: SortAndFilterProps): ReactElement => {
  const {
    totalBills,
    filterLabel,
    selectedFilters,
    filterOptions,
    setSelectedFilters,
    sortOrder,
    sortOptions,
    setSortOrder,
    actionButton,
    showViewDropdown,
    tagFilterOptions,
    setSelectedTags,
    selectedTags,
  } = props;

  if (!filterOptions || !selectedFilters || !setSelectedFilters) {
    return (
      <div className="row list-header">
        {actionButton || ''}
        <div className="total-count">
          {totalBills && (
            <span>
              {totalBills} {totalBills === 1 ? 'bill' : 'bills'}
            </span>
          )}
        </div>
        <div className={`col-xl-${showViewDropdown ? `10` : `12`} sort-view-group`}>
          <SortDropdown
            setSortOrder={setSortOrder}
            sortOptions={sortOptions}
            sortOrder={sortOrder}
          />
        </div>
        {showViewDropdown && (
          <div className="col-xs-1 dropdown-container">
            <BillViewDropdown />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="row list-header filter-header">
      <div className={'filter-group'}>
        <div className="total-count">
          {totalBills && (
            <span>
              {totalBills} {totalBills === 1 ? 'bill' : 'bills'}
            </span>
          )}
        </div>
        <div className="filter jurisdictions">
          <MultipleSelectDropDown
            currentSelectedValues={selectedFilters}
            onChange={(selectedFilterValues) => {
              setSelectedFilters(selectedFilterValues);
            }}
            options={filterOptions}
            title={`Filter by ${filterLabel}`}
          />
        </div>
        {selectedTags && tagFilterOptions && setSelectedTags && (
          <div className="filter tagnames">
            <MultipleSelectDropDown
              currentSelectedValues={selectedTags}
              onChange={(selectedTagValues) => {
                setSelectedTags(selectedTagValues);
              }}
              options={tagFilterOptions}
              title={`Filter by Tag Names`}
            />
          </div>
        )}
      </div>
      <div className={'sort-group'}>
        <SortDropdown
          setSortOrder={(newSortOrder) => {
            setSortOrder(newSortOrder);
          }}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
        />
      </div>
      {showViewDropdown && (
        <div className="dropdown-container">
          <BillViewDropdown />
        </div>
      )}
    </div>
  );
};

export default SortAndFilterHeader;
