import { SearchActionFilter } from './Bill';
import { Team } from './Team';

export interface SearchPagination {
  limit: number;
  offset?: number;
}

export interface SearchOrder {
  orderColumn: string;
  orderDirection?: 'ASC' | 'DESC';
}

export type Sorting = SearchOrder;

export interface SearchResult<DataType> {
  offset: number;
  limit: number;
  count: number;
  data: DataType[];
}

export enum BillSearchSortField {
  QueryRelevance = 'QueryRelevance',
  LastActionDate = 'LastActionDate',
  BillNumber = 'BillNumber',
  SalienceScore = 'SalienceScore',
  BillTitle = 'BillTitle',
  BillStatus = 'BillStatus',
  BillSession = 'BillSession',
  UserTags = 'UserTags',
  DemocratSponsorCount = 'DemocratSponsorCount',
  RepublicanSponsorCount = 'RepublicanSponsorCount',
}

export interface BillSearchSortParameter {
  field: BillSearchSortField;
  order: 'ASC' | 'DESC';
}

/** Represents a user's search request for bills in Enview. This interface is
 * used as the communication contract between the Enview frontend and backend
 * for search actions. These criteria are used both as input to the actual
 * search API function and as props to components that set the search UI
 * elements to the proper state to represent a given search.
 *
 * Objects matching this interface will also be saved as the
 * saved_searches.search_criteria `jsonb` column in the Enview postgres
 * database
 *
 * NOTE! Objects matching this schema are saved in the `search_criteria` column
 * of the `saved_searches` table. Making breaking changes to this schema should be
 * done only with a plan to update existing saved searches. See `CURRENT_SEARCH_CRITERIA_VERSION`
 * in backend/src/services/Teams/api/teams.ts
 */
export interface BillSearchCriteria {
  /** Text keyword query that is used to narrow search results from all of the
   * available bills in the sessions specified */
  query?: string;
  /** String list of bill statuses that should be included in search results.
   * Corresponds to the BillStatus type in this module */
  statuses?: string[];
  /** List of abbreviations for jursidictions that should be included in search
   * results. */
  jurisdictions: string[];
  /** List of committee names on which the list of bills should be filtered */
  committees?: string[];
  /** List of session ID numbers that should be included in search results */
  sessions?: number[];
  /** List of names of sessions that should be included in search results. If
   * included, this parameter is translated into a list of sessionIds and saved
   * as the `sesssions` parameter in the backend */
  sessionNames?: string[];
  /** Whether or not to use the "default sessions" for the given list of
   * `jurisdictions`. If included, this abstract list of default sessions is
   * materialized into a list of sessionIds and saved as the `sessions`
   * parameter in the backend */
  useDefaultSession?: boolean;
  /** List of legislative chamber types ('upper', 'lower') on which the list of
returned bills should be filtered
   */
  chambers?: string[];
  /** A list of specific bill numbers to be fetched in the search. This has the
   * effect of short-circuiting the query parameter and loading the given bills
   * directly from the Enview postgres database */
  billNumbers?: string[];
  /**
   * If defined, a set of criteria that filters the results of this search only
   * to include bills that have qualifying actions recorded in our database.
   * */
  actionFilter?: SearchActionFilter;
  /** List of tags for which all associated bills are to be fetched */
  tagIds?: number[];
  /** Describes how tag filter should be applied */
  tagFilterMode?: 'any' | 'all' | 'none';
  /** The ID number of a legislator for which all sponsored bills are to be
   * fetched */
  personId?: number;
  /** Whether or not to include bills that the given team is already tracking in
   * the search results. If false, only bills that are not tracked by the given
   * `teamId` will be included in results */
  includeTracked?: boolean;
  /** ID Number of the team to use when evaluating the `includeTracked`
   * parameter */
  teamId?: number;
  /** Whether or not to return only bills that have Momentum also known as Salience Score.
   * Only bills with Salience score value of 1 are included in results */
  salienceScore?: number;
  /** A list of legislative types to include in bill search results */
  legislativeTypes?: string[];
  /** List of bill policy topics from insights */
  insightsPolicyTopics?: string[];
  /** List of party short-names ('democrat', 'republican') signifying filters for: only bills
   * that have been sponsored by at least one member of that party */
  partySponsorFilters?: string[];
}

export interface SavedSearch {
  id: string;
  creatorUserId: number;
  createdAt: Date;
  team: Team;
  teamId: number;
  name: string;
  isArchived: boolean;
  subscribedToByUserIds: Array<{
    savedSearchId: string;
    userId: number;
    isFavorited: boolean;
    isSubscribed: boolean;
  }>;
  searchCriteria: BillSearchCriteria;
  author?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface BillSearchPagination extends SearchPagination {
  sortParameter: BillSearchSortParameter;
  limit: number;
  offset: number;
  additionalIncludes?: string[];
  /** List of team IDs the user has access to so we can answer, for sorting purposes,
   * "is this bill tagged by at least one of the user's teams?"*/
  userTeamIds?: number[];
}

export interface ElasticsearchBillSearchIndexDocument {
  id: string;
  actions: { classification: string; action_date: Date };
  text: string;
  session_name: string;
  bill_id: string;
  bill_name: string;
  bill_number: string;
  chamber: string;
  legislative_type: string;
  summary: string;
  status: string;
  alphanum_sort: string;
  jurisdiction_abbreviation: string;
  session_id: number;
  latest_update: string;
  sponsors: string[];
  latest_action_date: string;
  first_action_date: string;
  salience_score: number;
  insights_policy_topics: string[];
}

export type BillSearchRequestPagination = {
  page: number;
  pageSize: number;
  sortOrder?: SortOrder;
};

export type BillSearchParams = {
  criteria: BillSearchCriteria;
  pagination: BillSearchRequestPagination;
};

export type EndOfSessionReportParams = {
  criteria: {
    jurisdictionAbbreviation: string;
    policyTopicInsightName: string;
    sessionName: string;
  };
};

export enum SortBy {
  RELEVANCE = 'Relevance',
  RECENT = 'Latest Action',
  ALPHANUMERICAL = 'Alphanumerical',
  ALPHABETICAL = 'Alphabetical',
  CREATED = 'Date Created',
  ADDED = 'Date Added',
  RECENT_ACTIVITY = 'Latest Activity',
  STARRED = 'Starred',
  BILL_TITLE = 'Bill Title',
  BILL_STATUS = 'Bill Status',
  BILL_SESSION = 'Bill Session',
  HEARING_STATUS = 'Hearing Status',
  USER_TAGGED = 'User Tagged',
  DEM_SPONSOR_COUNT = 'Democractic Sponsor Count',
  REP_SPONSOR_COUNT = 'Republican Sponsor Count',
}

export enum SortByParam {
  OLDEST = 'oldest',
  LATEST = 'latest',
  ALPHANUMERICAL = 'alphanumerical',
  REVERSE_ALPHANUMERICAL = 'reverse-alphanumerical',
  RELEVANCE = 'relevance',
  STARRED = 'starred',
  BILL_TITLE = 'bill-title',
  REVERSE_BILL_TITLE = 'reverse-bill-title',
  BILL_SESSION = 'bill-session',
  REVERSE_BILL_SESSION = 'reverse-bill-session',
  BILL_STATUS = 'bill-status',
  REVERSE_BILL_STATUS = 'reverse-bill-status',
  HEARING_STATUS = 'hearing-status',
  REVERSE_HEARING_STATUS = 'reverse-hearing-status',
  USER_TAGGED = 'tags-asc',
  REVERSE_USER_TAGGED = 'tags-desc',
  DEM_SPONSORS = 'dem-sponsors-asc',
  REVERSE_DEM_SPONSORS = 'dem-sponsors-desc',
  REP_SPONSORS = 'rep-sponsors-asc',
  REVERSE_REP_SPONSORS = 'rep-sponsors-desc',
}

export interface SortOrder {
  sortBy: SortBy;

  isReversed: boolean;

  equals: (other: SortOrder) => boolean;

  getQueryParam: () => SortByParam | undefined;
}

export enum BillSearchView {
  Card = 'Card',
  Table = 'Table',
}
