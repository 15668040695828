import {
  SortOrder as ISortOrder,
  SortBy,
  SortByParam,
} from '@enview/interface/types/BillSearch';

export { SortBy, SortByParam } from '@enview/interface/types/BillSearch';

export class SortOrder implements ISortOrder {
  sortBy: SortBy;

  isReversed: boolean;

  constructor(sortBy: SortBy, isReversed: boolean) {
    this.sortBy = sortBy;
    this.isReversed = isReversed;
  }

  equals(other: SortOrder): boolean {
    return this.sortBy === other.sortBy && this.isReversed === other.isReversed;
  }

  getQueryParam(): SortByParam | undefined {
    switch (this.sortBy) {
      case SortBy.RECENT:
      case SortBy.RECENT_ACTIVITY:
        if (this.isReversed) {
          return SortByParam.OLDEST;
        }
        return SortByParam.LATEST;
      case SortBy.ALPHANUMERICAL:
      case SortBy.ALPHABETICAL:
        if (this.isReversed) {
          return SortByParam.REVERSE_ALPHANUMERICAL;
        }
        return SortByParam.ALPHANUMERICAL;
      case SortBy.CREATED:
      case SortBy.ADDED:
        if (this.isReversed) {
          return SortByParam.OLDEST;
        }
        return SortByParam.LATEST;
      case SortBy.RELEVANCE:
        return SortByParam.RELEVANCE;
      case SortBy.STARRED:
        return SortByParam.STARRED;
      case SortBy.BILL_TITLE:
        return this.isReversed
          ? SortByParam.REVERSE_BILL_TITLE
          : SortByParam.BILL_TITLE;
      case SortBy.BILL_SESSION:
        return this.isReversed
          ? SortByParam.REVERSE_BILL_SESSION
          : SortByParam.BILL_SESSION;
      case SortBy.BILL_STATUS:
        return this.isReversed
          ? SortByParam.REVERSE_BILL_STATUS
          : SortByParam.BILL_STATUS;
      case SortBy.HEARING_STATUS:
        return this.isReversed
          ? SortByParam.REVERSE_HEARING_STATUS
          : SortByParam.HEARING_STATUS;
      case SortBy.USER_TAGGED:
        return this.isReversed
          ? SortByParam.REVERSE_USER_TAGGED
          : SortByParam.USER_TAGGED;
      case SortBy.DEM_SPONSOR_COUNT:
        return this.isReversed
          ? SortByParam.REVERSE_DEM_SPONSORS
          : SortByParam.DEM_SPONSORS;
      case SortBy.REP_SPONSOR_COUNT:
        return this.isReversed
          ? SortByParam.REVERSE_REP_SPONSORS
          : SortByParam.REP_SPONSORS;
      // no default
    }
  }

  static getBillSortOrderFromParam = (param: string): SortOrder => {
    switch (param) {
      case SortByParam.LATEST:
        return new SortOrder(SortBy.RECENT, false);
      case SortByParam.OLDEST:
        return new SortOrder(SortBy.RECENT, true);
      case SortByParam.ALPHANUMERICAL:
        return new SortOrder(SortBy.ALPHANUMERICAL, false);
      case SortByParam.REVERSE_ALPHANUMERICAL:
        return new SortOrder(SortBy.ALPHANUMERICAL, true);
      case SortByParam.RELEVANCE:
        return new SortOrder(SortBy.RELEVANCE, false);
      case SortByParam.BILL_TITLE:
        return new SortOrder(SortBy.BILL_TITLE, false);
      case SortByParam.REVERSE_BILL_TITLE:
        return new SortOrder(SortBy.BILL_TITLE, true);
      case SortByParam.BILL_SESSION:
        return new SortOrder(SortBy.BILL_SESSION, false);
      case SortByParam.REVERSE_BILL_SESSION:
        return new SortOrder(SortBy.BILL_SESSION, true);
      case SortByParam.BILL_STATUS:
        return new SortOrder(SortBy.BILL_STATUS, false);
      case SortByParam.REVERSE_BILL_STATUS:
        return new SortOrder(SortBy.BILL_STATUS, true);
      case SortByParam.USER_TAGGED:
        return new SortOrder(SortBy.USER_TAGGED, false);
      case SortByParam.REVERSE_USER_TAGGED:
        return new SortOrder(SortBy.USER_TAGGED, true);
      case SortByParam.DEM_SPONSORS:
        return new SortOrder(SortBy.DEM_SPONSOR_COUNT, false);
      case SortByParam.REVERSE_DEM_SPONSORS:
        return new SortOrder(SortBy.DEM_SPONSOR_COUNT, true);
      case SortByParam.REP_SPONSORS:
        return new SortOrder(SortBy.REP_SPONSOR_COUNT, false);
      case SortByParam.REVERSE_REP_SPONSORS:
        return new SortOrder(SortBy.REP_SPONSOR_COUNT, true);
      default:
        return new SortOrder(SortBy.RECENT, false);
    }
  };
}
