import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TeamAPI } from '../../../../api';
import { SavedSearchAlertBell } from '../../../../components/savedSearches/SavedSearchAlertBell';
import { getTeamMode } from '../../../../dux';
import { State } from '../../../../dux/@types';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';

const SAVED_SEARCH_PATH = '/legislative-tracking/saved-search/';
const NO_SAVED_SEARCHES_MESSAGE = 'No saved searches';

const SavedSearchesSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;
  const teamModeId = useSelector(getTeamMode);
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const { data: savedSearches } = TeamAPI.endpoints.getSavedSearches.useQuery(
    useSelector(getTeamMode),
  );
  const { t } = useTranslation();

  const sortedSearches = useMemo(
    () =>
      savedSearches && [...savedSearches].sort((a, b) => a.name.localeCompare(b.name)),
    [savedSearches],
  );

  const mappedSavedSearches = sortedSearches?.map(
    (search): ReactElement => (
      <div className="d-flex saved-search-group" key={search.id}>
        {organizationUser && (
          <SavedSearchAlertBell
            className="saved-search-bell-shared"
            orgUser={organizationUser}
            savedSearch={search}
            showModalOnAdd
            teamId={teamModeId}
          />
        )}
        <NavLink
          activeClassName="active-nav-link"
          className="d-flex sub-item saved-search-item"
          id={search.id}
          onClick={onNavClick}
          to={`${SAVED_SEARCH_PATH}${search.id}`}
        >
          <span id={search.id}>
            <span>{search.name}</span>
          </span>
          <ReactTooltip
            anchorId={search.id}
            className="link-tooltip saved-search-tooltip tooltip-content"
            delayShow={200}
            place="right"
          >
            {search.name}
          </ReactTooltip>
        </NavLink>
      </div>
    ),
  );

  const getCardBody = (): ReactElement => {
    return (
      <>
        {mappedSavedSearches?.length ? mappedSavedSearches : NO_SAVED_SEARCHES_MESSAGE}
      </>
    );
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      customGateTooltipContent={
        <Trans
          components={{
            searchLink: <a href={t('urls.savedSearches')} target="_blank" />,
            gate: <p className="tooltip-header" />,
          }}
          i18nKey="featureGating.tooltipText.searchesSidebar"
          key="featureGating.tooltipText.searchesSidebar"
        />
      }
      eventKey="SEARCHES"
      header="Searches"
      icon={<FontAwesomeIcon icon={faSearchPlus} />}
    />
  );
};

export default SavedSearchesSidebarSection;
