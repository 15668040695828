import React, { ReactElement, useMemo } from 'react';
import { JurisdictionAPI } from '../../api';
import SingleSelectDropDown, {
  OptionType,
} from '../Shared/DropDowns/SingleSelectDropDown';

interface AnalyzerJurisdictionPickerProps {
  selectedJurisdiction: string;
  setSelectedJurisdiction: (selected: string) => void;
  onClick: (e?: React.SyntheticEvent) => void;
}

const AnalyzerJurisdictionPicker = (
  props: AnalyzerJurisdictionPickerProps,
): ReactElement => {
  const { selectedJurisdiction, setSelectedJurisdiction, onClick } = props;

  const { data: allJurisdictions, isFetching } =
    JurisdictionAPI.endpoints.getJurisdictions.useQuery();

  const jurisdictionOptions = useMemo(
    (): OptionType<string>[] =>
      allJurisdictions?.map((j) => ({
        label: j.name,
        value: j.abbreviation.toUpperCase(),
      })) ?? [],
    [isFetching, allJurisdictions],
  );

  const handleSelection = (opt: OptionType<string>) => {
    setSelectedJurisdiction(opt.value);
    onClick();
  };

  return (
    <SingleSelectDropDown
      defaultLabel={selectedJurisdiction || 'Jurisdiction'}
      height="450px"
      onSelect={handleSelection}
      options={jurisdictionOptions}
      selectedValue={selectedJurisdiction}
    />
  );
};

export default AnalyzerJurisdictionPicker;
