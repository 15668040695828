import React, { ReactElement, useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
import { loadOrgUserSucceeded, updateOrganizationUser } from '../../dux';
import { Link } from 'react-router-dom';
import { StripeAPI } from '../../api';
import Modal from 'react-modal';
import CloseIcon from '../../components/svg/CloseIcon';
import { updateOrgUserInitiated } from '../../dux/AccountDux';

const AnalyzerAccountSettings = (): ReactElement => {
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const loadingOrgUserSuccess = useSelector(loadOrgUserSucceeded);
  const updateRequestInitiated = useSelector(updateOrgUserInitiated);

  /* To get Stripe subscription details this component:
  1. Gets the cached info from our own database (getSubscriptionForUser)
  2. Calls updateSubscriptionDetails to refresh this cached subscription info from the Stripe API
  3. since updateSubscriptionDetails invalidates the (browser-level, rtk-query) cache of the subcription we got in step 1,
     that will liklely be re-fetched.

  This is probably not the only way to do it! But it seems to be a reasonable balance of speed and accuracy
  */
  const {
    data: userSubscription,
    isSuccess,
    isFetching,
    isError,
  } = StripeAPI.endpoints.getSubscriptionForUser.useQuery();

  const [updateSubscriptionDetails] =
    StripeAPI.endpoints.updateSubscriptionDetails.useMutation();

  useEffect(() => {
    if (userSubscription)
      void updateSubscriptionDetails(userSubscription?.subscriptionId);
  }, [userSubscription?.subscriptionId]);

  const [cancelSubscription, {}] = StripeAPI.endpoints.cancelSubscription.useMutation();

  const [firstName, setFirstName] = useState(organizationUser?.firstName || '');
  const [lastName, setLastName] = useState(organizationUser?.lastName || '');
  const [email, setEmail] = useState(organizationUser?.email || '');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  useEffect(() => {
    setFirstName(organizationUser?.firstName || '');
    setLastName(organizationUser?.lastName || '');
    setEmail(organizationUser?.email || '');
  }, [organizationUser]);

  const dispatch = useDispatch();

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const data = {
      firstName,
      lastName,
      email,
    };
    if (organizationUser) dispatch(updateOrganizationUser(data, organizationUser));
  };

  return (
    <>
      <h2 className="settings-header">Account Settings</h2>
      <div className="settings-container">
        <div className="form-header">
          <h3>Payment & Billing</h3>
          <div className="subtitle">Add or update payment details</div>
          <div className="form-container">
            {isFetching && <div>Loading subscription details...</div>}
            {isError && (
              <div>
                Error loading subscription details. You should try reloading the page
              </div>
            )}
            {organizationUser && isSuccess && userSubscription?.status === 'active' && (
              <div className="active-subscription">
                {userSubscription.cancelAtPeriodEnd ? (
                  <>
                    <div>
                      Your subscription has been canceled and no more payments will be
                      processed.
                    </div>
                    <div>
                      <span className="label">End Date:</span>
                      {new Date(userSubscription?.currentPeriodEnd).toDateString()}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-2">
                      <span className="label">Your Plan:</span>{' '}
                      <span className="value">$29</span> per month
                    </div>
                    <div className="mb-2">
                      <span className="label">Next Billing Date:</span>{' '}
                      {new Date(userSubscription?.currentPeriodEnd).toDateString()}
                    </div>
                  </>
                )}

                <div>
                  <span className="label">User Since:</span>{' '}
                  {new Date(organizationUser?.createdAt).toDateString()}
                </div>
                {!userSubscription.cancelAtPeriodEnd && (
                  <div className="form-actions">
                    <button
                      className="red"
                      onClick={() => {
                        setCancelDialogOpen(!cancelDialogOpen);
                      }}
                      type="button"
                    >
                      Cancel Subscription
                    </button>
                  </div>
                )}
              </div>
            )}
            {isSuccess &&
              (!userSubscription || userSubscription?.status !== 'active') && (
                <div className="subscription-section">
                  <div>Click here to enter payment details and complete signup</div>
                  <Link to="/analyzer/checkout">Access now for $29/month</Link>
                </div>
              )}
          </div>
        </div>
        <Modal
          className="react-modal admin analyzer"
          closeTimeoutMS={100}
          contentLabel="Modal"
          isOpen={cancelDialogOpen}
          onAfterOpen={() => {}}
          onRequestClose={() => {}}
          overlayClassName="react-modal-overlay"
        >
          <div className="modal-label analyzer">
            <CloseIcon
              className="float-right"
              onClick={() => setCancelDialogOpen(false)}
            />
          </div>
          This will cancel your subscription at the end of the current billing period.
          This cannot be undone. Are you sure?
          <div className="dialog-actions">
            <button
              onClick={() => {
                setCancelDialogOpen(false);
              }}
              type="button"
            >
              Go Back
            </button>{' '}
            <button
              className="red"
              onClick={() => {
                void cancelSubscription(userSubscription?.subscriptionId || '');
                setCancelDialogOpen(false);
              }}
              type="button"
            >
              Cancel Subscription
            </button>
          </div>
        </Modal>
      </div>
      <div className="settings-container">
        <div className="form-header">
          <h3>Your Account</h3>
          <div className="subtitle">Update your account details here</div>
        </div>
        <div className="form-container">
          <form onSubmit={onSubmit}>
            <label>
              First Name
              <input
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
                type="text"
                value={firstName}
              ></input>
            </label>
            <label>
              Last Name
              <input
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
                type="text"
                value={lastName}
              ></input>
            </label>
            <label>
              Email Address
              <input
                disabled
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                value={email}
              ></input>
            </label>
            {updateRequestInitiated && loadingOrgUserSuccess && (
              <div className="success-message">Account details saved</div>
            )}

            <div className="form-actions">
              <button>Edit</button>
              <button className="dark-green">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AnalyzerAccountSettings;
