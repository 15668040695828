import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import './DemonstrationSummary.scss';
import { BillAPI } from '../../api';
import BillSummaryDisplay from './BillSummaryDisplay';
import { skipToken } from '@reduxjs/toolkit/dist/query';

type DemonstrationUrlParams = {
  billId: string;
};

const DemonstrationSummary = (): ReactElement => {
  const params = useParams<DemonstrationUrlParams>();
  const billId = params.billId;

  const {
    data: bill,
    isSuccess: hasBillData,
    isFetching: isBillFetching,
    isError: isBillError,
  } = BillAPI.endpoints.getBill.useQuery({
    billId,
    teamId: undefined,
  });

  const getIntroducedVersionId = (): number | undefined => {
    if (bill && bill.versions && bill.versions.length > 0) return bill.versions[0].id;
    return undefined;
  };

  const introducedVersionId = useMemo(() => getIntroducedVersionId(), [bill]);

  const {
    data: aiSummary,
    isFetching: isAiSummaryFetching,
    isError: isAiSummaryError,
  } = BillAPI.endpoints.getBillAutoSummaryDemonstration.useQuery(
    bill && introducedVersionId
      ? {
          billId: bill.id,
          billVersionId: introducedVersionId,
        }
      : skipToken,
  );

  return (
    <div className="demonstration-summary">
      {isBillFetching && <div className="my-2">Loading...</div>}
      {isBillError && <div className="my-2">Error occurred loading bill details</div>}
      {hasBillData && (
        <BillSummaryDisplay
          aiSummaryData={aiSummary}
          aiSummaryDataError={isAiSummaryError}
          aiSummaryDataFetching={isAiSummaryFetching}
          bill={bill}
          introducedVersionId={introducedVersionId || -1}
          nav
        />
      )}
    </div>
  );
};

export default DemonstrationSummary;
