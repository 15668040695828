import React, { ReactElement } from 'react';
import loader1 from '../../static/loader-1.svg';
import loader2 from '../../static/loader-2.svg';
import loader3 from '../../static/loader-3.svg';
const AnalyzerLoadingAnimation = (props: { message: string }): ReactElement => {
  const { message } = props;
  return (
    <div id="loader">
      <span className="my-2">{message}</span>
      <img id="l3" src={loader1} />
      <img id="l2" src={loader2} />
      <img id="l1" src={loader3} />
    </div>
  );
};

export default AnalyzerLoadingAnimation;
