/* eslint-disable react/require-default-props */
import { Bill, BillSponsor } from '@enview/interface/types/bills/Bill';
import { BillSummary } from '@enview/interface/types/bills/BillSummary';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash-es';
import {
  getMostRecentAction,
  renderBillActionDate,
  translateBillStatusForBadge,
} from '../../../helpers/BillHelper';
import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTeamMode } from '../../../dux';
import { convertIdToAbbreviatedTitle, getBillAlias } from '../../../helpers/BillHelper';
import { SortBy, SortOrder } from '../../../models/SortOrder';
import BillStatusBadge from '../../LegislativeTracking/BillStatusBadge';
import { SortableDataTableColumn } from '../../LegislativeTracking/TabularBillSearchResults';
import BillTagButton from '../BillTagging/BillTagButton';
import BillTrackingButton from '../BillTracking/BillTrackingButton';
import BillTableHeader, { BillTableFilterProperties } from './BillTableHeader';
import BillPosition from '../BillPosition/BillPosition';
import {
  democratPartyIdentifier,
  getJurisdiction,
  partyString,
  republicanPartyIdentifier,
} from '../../../utils';
import Info from '../../../components/svg/InfoCircleIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { DecodedValueMap, QueryParamConfigMap, SetQuery } from 'use-query-params';

type BillTableProps = {
  results: { data: any; count: number };
  searching: boolean;
  setSearching: (arg: boolean) => void;
  useServerSidePagination?: boolean;
  query: DecodedValueMap<QueryParamConfigMap>;
  setQuery: SetQuery<QueryParamConfigMap>;
  showFilterProperties: boolean;
  filterProperties?: BillTableFilterProperties;
};

const BillTable = (props: BillTableProps): ReactElement => {
  const {
    results,
    searching,
    setSearching,
    useServerSidePagination,
    showFilterProperties,
    filterProperties,
    query,
    setQuery,
  } = props;

  const teamModeId = useSelector(getTeamMode);
  const [selectedRows, setSelectedRows] = useState([] as Bill[]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [tooltipAnchor, setTooltipAnchor] = useState('');
  const [tipContent, setTipContent] = useState<ReactElement<any>>(<></>);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (typeof setQuery === 'function') setQuery({ pageSize: 20 });
  }, [setQuery]);

  const handleSelectedRowsChange = useCallback(
    (state: { selectedRows: Bill[] }) => {
      setSelectedRows(state.selectedRows);
    },
    [setSelectedRows],
  );

  const handleSelectableRowsSelected = useCallback(
    (row: Bill) => {
      return selectedRows.some((selectedRow) => selectedRow.id === row.id);
    },
    [selectedRows, setSelectedRows],
  );

  const handlePageChange = useCallback((pageParam: number) => {
    setLoadingPage(true);
    if (typeof setQuery === 'function') setQuery({ page: pageParam });
  }, []);

  const handlePageSizeChange = useCallback((pageSizeParam: number) => {
    setLoadingPage(true);
    if (typeof setQuery === 'function') setQuery({ pageSize: pageSizeParam });
  }, []);

  useEffect(() => {
    setSearching(false);
    if (results) setLoadingPage(false);
    // Update the selected rows based on the new results data
    setSelectedRows((prevSelectedRows) => {
      const updatedRowSelections =
        results?.data?.filter((result: { id: string }) => {
          return prevSelectedRows.some((selectedRow) => {
            return result.id === selectedRow.id;
          });
        }) ?? [];
      return updatedRowSelections;
    });
  }, [results]);

  const showTableResults = !searching && results && results.count > 0;

  const [sort, setSort] = useState<SortOrder>(
    new SortOrder(SortBy.ALPHANUMERICAL, false),
  );

  const handleSortChange = useCallback(
    (column: SortableDataTableColumn, sortDirection: 'desc' | 'asc') => {
      const sortColumn = column.sortKey ?? SortBy.RECENT_ACTIVITY;
      const sortOrder = new SortOrder(sortColumn, sortDirection === 'desc');
      if (isEqual(sort, sortOrder)) return;
      setLoadingPage(true);
      setSort(sortOrder);
      if (typeof setQuery === 'function')
        setQuery({
          order: sortOrder.getQueryParam(),
        });
    },
    [sort, setQuery],
  );

  const serverSideProperties = {
    paginationDefaultPage: query?.page ?? 1,
    paginationPerPage: query?.pageSize ?? 10,
    paginationServer: true,
    onSort: (column: IDataTableColumn, direction: 'asc' | 'desc') =>
      handleSortChange(column, direction),
    onChangePage: (pageParam: number) => handlePageChange(pageParam),
    onChangeRowsPerPage: (pageSizeParam: number) => handlePageSizeChange(pageSizeParam),
    paginationServerOptions: { persistSelectedOnSort: true },
    sortServer: true,
  };

  const handleTooltipContentClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const tooltipContent = (
    primarySponsors: BillSponsor[],
    coSponsors: BillSponsor[],
  ) => {
    return (
      <div className="sponsors-tooltip" onClick={handleTooltipContentClick}>
        <span className="tooltip-header">Primary sponsors:</span>
        <ul>
          {primarySponsors.map((sponsor, index) => (
            <li key={`primary-${index}`}>
              {sponsor.person?.id ? (
                <Link to={`/person/${sponsor.person?.id}`}>
                  {`${sponsor.name}${partyString(sponsor)}`}
                </Link>
              ) : (
                `${sponsor.name}${partyString(sponsor)}`
              )}
            </li>
          ))}
        </ul>
        {!!coSponsors.length && (
          <>
            <span className="tooltip-header">Co-sponsors:</span>
            <ul>
              {coSponsors.map((sponsor, index) => (
                <li key={`co-${index}`}>
                  {sponsor.person?.id ? (
                    <Link to={`/person/${sponsor.person?.id}`}>
                      {`${sponsor.name}${partyString(sponsor)}`}
                    </Link>
                  ) : (
                    `${sponsor.name}${partyString(sponsor)}`
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  const renderBillSponsors = (bill: Bill) => {
    const { sponsors } = bill;
    if (!sponsors) return undefined;

    const currentSponsors = sponsors.filter((s) => !s.isRemoved);
    const primarySponsors = currentSponsors.filter((s) => s.isPrimary);
    const coSponsors = currentSponsors.filter((s) => !s.isPrimary && !s.isRemoved);

    if (!primarySponsors.length) return undefined;

    const tooltipId = `table-tooltip-${bill.id}`;

    const handleSponsorFocus = () => {
      setTipContent(tooltipContent(primarySponsors, coSponsors));
      setTooltipAnchor(tooltipId);
      setShowTooltip(true);
    };

    return (
      <p
        className="bill-sponsor-table-cell"
        id={tooltipId}
        key={bill.id}
        onClick={handleSponsorFocus}
        onMouseEnter={handleSponsorFocus}
      >
        <span>{primarySponsors[0].name + partyString(primarySponsors[0])}</span>
        <Info className="ml-1" color="#4a4a4a" size={16} />
      </p>
    );
  };

  const columns: SortableDataTableColumn[] = useMemo(
    () => [
      {
        name: 'Tracking',
        selector: 'teams',
        ignoreRowClick: true,
        button: true,
        minWidth: '110px',
        cell: (bill: Bill) => (
          <BillTrackingButton bill={bill} hasStatusIndicator isCompactMode />
        ),
      },
      {
        name: 'Tags',
        selector: (bill: Bill) => bill.tags.length,
        sortable: true,
        sortKey: SortBy.USER_TAGGED,
        ignoreRowClick: true,
        button: true,
        cell: (bill: Bill) => {
          return useMemo(
            () => <BillTagButton bill={bill} hasStatusIndicator />,
            [bill.tags],
          );
        },
      },
      {
        name: 'Position',
        selector: 'position',
        ignoreRowClick: true,
        right: true,
        width: '75px',
        cell: (bill: Bill) => {
          return useMemo(
            () => <BillPosition bill={bill} />,
            [], // TODO: Update to re-evaluate if bill position changes
          );
        },
      },
      {
        name: 'Bill ID',
        selector: (bill: Bill) => {
          return `${getJurisdiction(bill)}${bill.alphanumSort}`;
        },
        sortable: true,
        maxWidth: '150px',
        sortKey: SortBy.ALPHANUMERICAL,
        cell(bill: Bill) {
          const title = convertIdToAbbreviatedTitle(bill.id);
          const billAlias = getBillAlias(bill.userAliases, teamModeId);
          return (
            <Link
              className="bill-link"
              to={`/legislative-tracking/bill/details/${bill.id}`}
            >
              {title}
              {billAlias ? `(${billAlias.alias})` : ''}
            </Link>
          );
        },
      },
      {
        name: 'Bill Title',
        selector: 'name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '700px',
        sortKey: SortBy.BILL_TITLE,
        cell: (bill: Bill) => {
          // Need to use cell here to apply the custom wrap behavior
          return bill.name;
        },
      },
      {
        name: 'Bill Sponsors',
        selector: 'sponsors',
        sortable: false,
        minWidth: '150px',
        maxWidth: '300px',
        style: { position: 'relative', zIndex: 2 },
        cell: (bill: Bill) => renderBillSponsors(bill),
      },
      {
        name: '(D)',
        sortable: true,
        sortKey: SortBy.DEM_SPONSOR_COUNT,
        selector: (bill: Bill) =>
          bill.sponsors?.filter((s) => partyString(s) === democratPartyIdentifier)
            .length,
        minWidth: '50px',
        maxWidth: '75px',
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        cell: (bill: Bill) => {
          const { sponsors } = bill;
          if (!sponsors) return undefined;
          const count = sponsors.filter(
            (sponsor) => partyString(sponsor) === democratPartyIdentifier,
          ).length;
          return <p className="text-center">{count}</p>;
        },
      },
      {
        name: '(R)',
        sortable: true,
        sortKey: SortBy.REP_SPONSOR_COUNT,
        selector: (bill: Bill) =>
          bill.sponsors?.filter((s) => partyString(s) === republicanPartyIdentifier)
            .length,
        minWidth: '50px',
        maxWidth: '75px',
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        cell: (bill: Bill) => {
          const { sponsors } = bill;
          if (!sponsors) return undefined;
          const count = sponsors.filter(
            (sponsor) => partyString(sponsor) === republicanPartyIdentifier,
          ).length;
          return <p className="text-center">{count}</p>;
        },
      },
      {
        name: 'Bill Status',
        selector: (bill: Bill) => {
          // BillStatusBadge is defaulting to "Introduced" in case status is falsy
          return bill.status ? translateBillStatusForBadge(bill.status) : 'introduced';
        },
        sortable: true,
        ignoreRowClick: true,
        button: true,
        minWidth: '130px',
        maxWidth: '130px',
        sortKey: SortBy.BILL_STATUS,
        cell: (bill: Bill) => {
          return (
            <div>
              <BillStatusBadge bill={bill} isCompactMode />
            </div>
          );
        },
      },
      {
        name: 'Session',
        selector: 'session.displayName',
        sortKey: SortBy.BILL_SESSION,
        sortable: true,
        maxWidth: '150px',
        wrap: true,
      },
      {
        name: 'Latest Action',
        selector: 'action_description',
        sortable: false,
        maxWidth: '150px',
        cell: (bill: BillSummary) => {
          return getMostRecentAction(bill)?.description;
        },
      },
      {
        name: 'Action Date',
        selector: (row) => getMostRecentAction(row)?.actionDate,
        sortable: true,
        minWidth: '120px',
        maxWidth: '150px',
        sortKey: SortBy.RECENT_ACTIVITY,
        cell: (bill: BillSummary) => {
          const recentAction = getMostRecentAction(bill);
          return recentAction ? renderBillActionDate(recentAction, 'short') : '';
        },
      },
      {
        name: 'Committees',
        selector: (row) =>
          row.committeeMentions.map((x: { name: string }) => x.name).join(', '),
        maxWidth: '300px',
        cell: (bill: BillSummary) => {
          return bill.committeeMentions.map((x: { name: string }) => x.name).join(', ');
        },
      },
    ],
    [],
  );

  const getDefaultSort = useCallback((): {
    selector: string;
    direction: 'asc' | 'desc';
  } => {
    const sorderOrderKey = sort?.sortBy ?? SortBy.ALPHANUMERICAL;
    const selector =
      columns
        .find((column) => column.sortKey === sorderOrderKey)
        ?.sortKey?.toString() ?? SortBy.ALPHANUMERICAL;
    return { selector, direction: sort?.isReversed ? 'desc' : 'asc' };
  }, [sort, columns]);

  const table: ReactElement = (
    <DataTable
      columns={columns}
      data={results.data}
      defaultSortAsc={getDefaultSort().direction === 'asc'}
      defaultSortField={getDefaultSort().selector}
      disabled={loadingPage}
      noContextMenu
      onSelectedRowsChange={handleSelectedRowsChange}
      pagination
      paginationRowsPerPageOptions={[20, 50]}
      paginationTotalRows={results.count}
      responsive
      selectableRowSelected={handleSelectableRowsSelected}
      selectableRows
      selectableRowsVisibleOnly
      sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
      {...(useServerSidePagination ? serverSideProperties : {})}
    />
  );

  return (
    <>
      <div className="list results-pane">
        <div className="container result-count">
          <BillTableHeader
            filterProperties={filterProperties}
            query={query}
            results={results}
            selectedRows={selectedRows}
            setQuery={setQuery}
            showFilterProperties={showFilterProperties}
          />
          {loadingPage && (
            <div className="table-spinner-overlay">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
          {showTableResults && (
            <div className="table bill-table filtered-table">
              <ReactTooltip
                anchorId={tooltipAnchor}
                className="tooltip"
                clickable
                delayHide={1000}
                isOpen={showTooltip}
                place="bottom"
                setIsOpen={setShowTooltip}
              >
                {tipContent}
              </ReactTooltip>
              {table}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BillTable;
