import React, { ReactElement, useEffect, useState } from 'react';
import './Analyzer.scss';
import { Link } from 'react-router-dom';
import arrow from '../../static/arrow-circle-broken-right.svg';

import { logout } from '../../dux';

import { useDispatch } from 'react-redux';
import AnalyzerTopBar from './AnalyzerTopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faInfoCircle,
  faSearch,
  faSignOutAlt,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';

const AnalyzerLoggedInLayout = (props: { children: ReactElement }): ReactElement => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 768px)').matches,
  );
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const { children } = props;
  return (
    <>
      <AnalyzerTopBar />
      <div className="fixed-layout-inner-scroll">
        <div className="fixed-height-content">
          <aside className={`left-nav ${!isMobile && menuOpen ? 'open' : 'collapsed'}`}>
            {!isMobile && (
              <button className="toggle-drawer" onClick={() => setMenuOpen(!menuOpen)}>
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
              </button>
            )}
            <div className="sidebar-content">
              {isMobile || !menuOpen ? (
                <div className="mobile mt-4">
                  <a
                    href="https://pluralpolicy.com/bill-tracking-intelligence-price/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </a>
                  <Link to="/analyzer/search">
                    <FontAwesomeIcon icon={faSearch} />
                  </Link>
                  <Link to="/analyzer/settings">
                    <FontAwesomeIcon icon={faCog} />
                  </Link>
                  <a href="#" onClick={() => dispatch(logout())}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </a>
                </div>
              ) : (
                <>
                  <h2 className="mt-4">Better public policy intelligence.</h2>
                  <a
                    className="callout-button"
                    href="https://pluralpolicy.com/bill-tracking-intelligence-price/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Tools for <br /> Organizations & Teams
                    <img alt="arrow" className="link-arrow" src={arrow} />
                  </a>
                  <div className="links mt-auto">
                    <Link to="/analyzer/search">Search Bills</Link>
                    <Link to="/analyzer/settings">Account Settings</Link>
                    <a href="#" onClick={() => dispatch(logout())}>
                      Log Out <FontAwesomeIcon className="ml-2" icon={faSignOutAlt} />
                    </a>
                  </div>
                </>
              )}
            </div>
          </aside>
          <main>{children}</main>
        </div>
        <footer>
          <span>&copy; Plural Policy, Inc.</span>
        </footer>
      </div>
    </>
  );
};

export default AnalyzerLoggedInLayout;
