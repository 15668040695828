/* eslint-disable react/require-default-props */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader';
import { useSelector } from 'react-redux';
import {
  ArrayParam,
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Bill } from '@enview/interface/types/bills/Bill';
import BillList from '../Shared/Lists/BillList';
import Paginator from '../Shared/PageElements/Paginator';
import {
  SearchActionFilter,
  SearchActionFilterType,
} from '@enview/interface/types/Bill';

import { SortBy, SortOrder } from '../../models/SortOrder';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

import { BillSearchView } from '@enview/interface/types/BillSearch';
import * as Analytics from '../../analytics/TeamAnalytics';
import { BillAPI, TagAPI } from '../../api';
import { getJurisdictionsForTeamMode, getTeamMode } from '../../dux/TeamModeDux';
import { sortBillsBySortLabel } from '../../utils';
import SortAndFilterHeader from '../Shared/Lists/SortAndFilterHeader';
import BillTable from '../Shared/Tables/BillTable';
import {
  AdvancedFilterOption,
  filterBillsByBillActions,
  filterBillsByJurisdiction,
  filterBillsByPartySponsors,
  filterBillsByStatus,
  filterBillsByTags,
} from '../../helpers/BillHelper';

const NO_BILL_MESSAGE = "You haven't tracked any bills yet.";
const TEAM_NO_BILL_MESSAGE = 'Your team has not tracked any bills yet.';
const TEAM_SORT_BILL_OPTIONS = [
  new SortOrder(SortBy.RECENT, false),
  new SortOrder(SortBy.RECENT, true),
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

type TeamBillsProps = {
  orgUser: OrganizationUser;
  showJurisdictionFilter: boolean;
  filterToJurisdictions?: string[];
  noBillsMessage?: string;
  view?: BillSearchView;
};

// Probably a better way to do this, but I want a cheap way to
// get around the type system and use the results of an ArrayParam as a string[]
const queryParamToArray = (
  arrayParam: (string | null)[] | null | undefined,
): string[] => {
  return arrayParam?.map((s) => (typeof s === 'string' ? s : '')) ?? [];
};

const TeamBills = (props: TeamBillsProps): ReactElement => {
  const {
    orgUser,
    showJurisdictionFilter,
    filterToJurisdictions,
    noBillsMessage: noBillsMsgFromProps,
    view = BillSearchView.Card,
  } = props;
  const teamModeId = useSelector(getTeamMode);
  const orgJurisdictions = useSelector(getJurisdictionsForTeamMode);
  const [sortOrder, setSortOrder] = useState(new SortOrder(SortBy.RECENT, false));
  const { trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined, {
    selectFromResult: ({ data: bills }): { trackedBills: Bill[] } => {
      if (!bills) return { trackedBills: [] };
      return {
        trackedBills: sortBillsBySortLabel(
          bills.filter((b) => !!b.teams?.find((t) => t.id === teamModeId)),
          sortOrder,
        ),
      };
    },
  });
  const { data: filteredTags } = TagAPI.endpoints.getTags.useQuery(undefined);

  const initialJurisdictions =
    filterToJurisdictions && filterToJurisdictions.length > 0
      ? filterToJurisdictions.map((jurisAbbr) => jurisAbbr.toLowerCase())
      : [];

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 20),
    jurisdictions: withDefault(ArrayParam, initialJurisdictions),
    status: ArrayParam,
    sessions: ArrayParam,
    searchActionType: StringParam,
    searchActionFromDate: DateParam,
    searchActionToDate: DateParam,
    sponsors: ArrayParam,
  });

  let noBillsMessage = noBillsMsgFromProps;
  if (!noBillsMessage) {
    noBillsMessage =
      teamModeId === orgUser.teamId ? NO_BILL_MESSAGE : TEAM_NO_BILL_MESSAGE;
  }

  const [searching, setSearching] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [actionFilter, setActionFilter] = useState<SearchActionFilter | undefined>(
    undefined,
  );
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [partySponsorFilters, setPartySponsorFilters] = useState<string[]>([]);
  const [selectedTagFilterOption, setSelectedTagFilterOption] = useState<string>('any');

  const trackFilter = useCallback(() => {
    Analytics.trackFilterWorkspaceBills(
      queryParamToArray(query.jurisdictions),
      teamModeId,
      orgUser,
    );
  }, [query, teamModeId, orgUser]);

  useEffect(() => {
    trackFilter();
  }, [trackFilter, query]);

  useEffect(() => {
    setQuery({ page: query.page });
    window.scrollTo(0, 0);
  }, [query]);

  const filterOptions = useMemo((): OptionType<string>[] => {
    const jurisdictionsTracked = trackedBills
      .map((bill) => bill.session.state.abbreviation)
      .filter((item, i, ar) => ar.indexOf(item) === i);
    return orgJurisdictions
      .filter((jur) => jurisdictionsTracked.includes(jur.abbreviation))
      .map((jur) => {
        return {
          label: jur.name,
          value: jur.abbreviation,
        };
      });
  }, [trackedBills]);

  const tagOptions = useMemo((): OptionType<string>[] | undefined => {
    return filteredTags?.map((tag) => {
      return {
        label: tag.name,
        value: tag.id.toString(),
      };
    });
  }, [filteredTags]);

  const filteredTrackedBills = useMemo(() => {
    let filteredBills = trackedBills;

    filteredBills = filterBillsByJurisdiction(
      filteredBills,
      queryParamToArray(query.jurisdictions),
    );

    filteredBills = filterBillsByTags(
      filteredBills,
      selectedTags,
      selectedTagFilterOption as AdvancedFilterOption,
    );

    filteredBills = filterBillsByStatus(filteredBills, queryParamToArray(query.status));

    filteredBills = filterBillsByPartySponsors(
      filteredBills,
      queryParamToArray(query.sponsors),
    );

    if (
      query.searchActionFromDate &&
      query.searchActionToDate &&
      query.searchActionType
    ) {
      filteredBills = filterBillsByBillActions(filteredBills, {
        type: query.searchActionType as SearchActionFilterType,
        fromDate: query.searchActionFromDate,
        toDate: query.searchActionToDate,
      });
    }

    return filteredBills;
  }, [query, selectedTags, trackedBills]);

  // Pagination
  const pageSize = 25;
  const pages = {
    page: query.page,
    total: filteredTrackedBills.length,
    pageSize,
  };
  const offset = (query.page - 1) * pageSize;
  const currentPageOfBills = filteredTrackedBills.slice(offset, offset + pageSize);
  if (!filteredTrackedBills) {
    return <Loader loaded={filteredTrackedBills} />;
  }

  const renderBillList: () => ReactElement = () => {
    return (
      <>
        <SortAndFilterHeader
          filterLabel={'Jurisdiction'}
          filterOptions={showJurisdictionFilter ? filterOptions : undefined}
          selectedFilters={queryParamToArray(query.jurisdictions)}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setSortOrder={setSortOrder}
          showViewDropdown={true}
          sortOptions={TEAM_SORT_BILL_OPTIONS}
          sortOrder={sortOrder}
          tagFilterOptions={tagOptions}
          totalBills={pages.total}
        />
        <BillList bills={currentPageOfBills} noBillsMessage={noBillsMessage} />
        <Paginator onChange={(page) => setQuery({ page })} pages={pages} />
      </>
    );
  };

  const renderBillTable: () => ReactElement = () => {
    const results = {
      data: filteredTrackedBills,
      count: filteredTrackedBills?.length,
    };
    const jurisdictionFilterProperties = {
      show: showJurisdictionFilter,
      filterOptions: filterOptions,
      tagFilterOptions: tagOptions,
      setSelectedTags,
      selectedTags,
      actionFilter,
      setActionFilter,
      statusFilters,
      setStatusFilters,
      partySponsorFilters,
      setPartySponsorFilters,
      selectedTagFilterOption,
      setSelectedTagFilterOption,
    };
    return (
      <BillTable
        filterProperties={jurisdictionFilterProperties}
        query={query}
        results={results}
        searching={searching}
        setQuery={setQuery}
        setSearching={setSearching}
        showFilterProperties={showJurisdictionFilter}
      />
    );
  };

  return (
    <div className="bill-search-container">
      <div className="results-pane list">
        {view === BillSearchView.Card ? renderBillList() : renderBillTable()}
      </div>
    </div>
  );
};

export default TeamBills;
