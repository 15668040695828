/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^b"] }] */
import {
  BillSearchParams,
  EndOfSessionReportParams,
} from '@enview/interface/types/BillSearch';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { Team, TeamMembership } from '@enview/interface/types/Team';
import { Bill, BillDetailed } from '@enview/interface/types/bills/Bill';
import { BillAlias, BillSummary } from '@enview/interface/types/bills/Bill';
import { InsightsPolicyTopic } from '@enview/interface/types/bills/InsightsPolicyTopic';
import { EndOfSessionReport } from '@enview/interface/types/bills/EndOfSessionReport';
import {
  PositionStatement,
  PositionType,
} from '@enview/interface/types/bills/PositionStatement';
import {
  BillAISummariesGeneratedResponse,
  BillAutoSummaryParams,
  BillAutoSummaryResponse,
  BillVersionToVersionAutoSummaryParams,
  BillVersionToVersionAutoSummaryResponse,
} from '@enview/interface/types/dataInsightsAPI/BillAutoSummary';
import { Tag } from '@enview/interface/types/tags/Tag';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { assign } from 'lodash-es';
import queryString from 'query-string';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as BillAnalytics from '../analytics/BillAnalytics';
import { State } from '../dux/@types';
import { addBillListCacheKey, removeBillListCacheKey } from '../dux/BillViewDux';
import base, { ApiTag } from './base';
import { TeamAPI } from './index';
import { RichTextNode } from '@enview/interface/types/actions/UserAction';

const BILL_LISTS_ID = 'BillLists';
const SEARCH_BILL_LIST = 'SearchBillList';
const TRACKED_BILL_LIST_ID = 'TrackedBillList';
const SPONSORED_BILL_LIST_ID = 'SponsoredBillList';
const TAGGED_BILL_LIST_ID = 'TaggedBillList';

type BillSearchResult = {
  data: BillSummary[];
  count: number;
};

type SimilarBillsParams = {
  bill: BillDetailed;
  versionId: number;
  jurisdictions: string[];
  orgUser: OrganizationUser;
  searchTypes: string[];
};

type GetPositionParams = {
  billId: string;
  orgId: number;
};

export type CreatePositionDto = {
  positionType: PositionType;
  positionStatement: string;
  statementJson?: RichTextNode;
  isArchived?: boolean;
  isPublic?: boolean;
  orgId: number;
  billId: string;
};

type UpdatePositionParams = {
  id: number;
  updateData: Partial<CreatePositionDto>;
};

export type SimilarBillHit = {
  _id: string;
  _score: number;
  _source: {
    bill_id: string;
    jurisdiction_abbreviation: string;
    bill_number: string;
    bill_name: string;
  };
};

type SimilarBillResult = {
  meta: { [key: string]: any };
  result: { hits: { hits: SimilarBillHit[] } };
};

type TrackedBillsLimit = {
  bills: string[];
  limit: number | null;
};

export const convertParamsToQuery = (params: BillSearchParams): string => {
  const {
    jurisdictions,
    sessionNames,
    statuses,
    chambers,
    legislativeTypes,
    salienceScore,
    includeTracked,
    teamId,
    query,
    actionFilter,
    insightsPolicyTopics,
    tagIds,
    partySponsorFilters,
    tagFilterMode,
  } = params.criteria;
  const { page, pageSize, sortOrder } = params.pagination;
  const urlParams: string[] = [];
  if (jurisdictions) {
    urlParams.push(...jurisdictions.map((filter) => `state=${filter}`));
  }
  if (sessionNames) {
    urlParams.push(
      // Session Names come from the wider world and can have #, &, or other characters
      // that could break our search query string
      ...sessionNames.map((filter) => `sessionName=${encodeURIComponent(filter)}`),
    );
  }
  if (sortOrder) {
    urlParams.push(`order=${sortOrder.getQueryParam()}`);
  }
  if (statuses && statuses.length) {
    urlParams.push(...statuses.map((filter) => `status=${filter}`));
  } else if (statuses) {
    urlParams.push(`status=passed`);
  }
  if (partySponsorFilters && partySponsorFilters.length) {
    urlParams.push(...partySponsorFilters.map((sponsor) => `sponsor=${sponsor}`));
  }
  if (chambers) {
    urlParams.push(...chambers.map((filter) => `chamber=${filter}`));
  }
  if (legislativeTypes && legislativeTypes.length) {
    urlParams.push(...legislativeTypes.map((filter) => `legislativeType=${filter}`));
  }
  if (actionFilter) {
    urlParams.push(
      `searchActionType=${actionFilter.type}&searchActionFromDate=${actionFilter.fromDate}&searchActionToDate=${actionFilter.toDate}`,
    );
  }
  if (includeTracked) {
    urlParams.push(`includeTracked=${includeTracked}`);
  }
  if (salienceScore) {
    urlParams.push(`salienceScore=${salienceScore}`);
  }
  if (teamId) {
    urlParams.push(`teamId=${teamId}`);
  }
  if (insightsPolicyTopics && insightsPolicyTopics.length) {
    urlParams.push(
      ...insightsPolicyTopics.map(
        (filter) => `insightsPolicyTopic=${encodeURIComponent(filter)}`,
      ),
    );
  }
  if (query) {
    const encodeQuery = encodeURIComponent(query);
    urlParams.push(`query=${encodeQuery}`);
  }
  if (page && pageSize) {
    urlParams.push(`offset=${(page - 1) * pageSize}&limit=${pageSize}`);
  }
  if (tagIds) {
    urlParams.push(...tagIds.map((filter) => `tag=${filter}`));
  }
  if (tagFilterMode) {
    urlParams.push(`tagFilterMode=${tagFilterMode}`);
  }
  return urlParams.join('&');
};

export const convertEOSRParamsToQuery = (params: EndOfSessionReportParams): string => {
  const { jurisdictionAbbreviation, policyTopicInsightName, sessionName } =
    params.criteria;
  return `jurisdictionAbbreviation=${jurisdictionAbbreviation}&policyTopicInsightName=${encodeURIComponent(
    policyTopicInsightName,
  )}&sessionName=${sessionName}`;
};

// Use this function with any api call that returns a list of bills that will be
// cached and needs to be optimistically updated when a bill changes.

const updateBillListCacheKey = async (
  arg: any,
  { dispatch, getCacheEntry, cacheDataLoaded, cacheEntryRemoved }: any,
): Promise<void> => {
  await cacheDataLoaded;
  const endpoint = getCacheEntry().endpointName || '';
  dispatch(addBillListCacheKey(endpoint, arg));
  await cacheEntryRemoved;
  dispatch(removeBillListCacheKey(endpoint, arg));
};

// If the api response has been added to the cached bill list, add a switch case
// for the endpoint and create an update recipe that iterates through each bill
// and calls the provided updateBillRecipe.

const updateCachedBillLists = (
  state: State,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  updateBillRecipe: (bill: Bill) => void,
): PatchCollection[] => {
  const cachedBillLists = state.billView.billListCacheKeys;
  const patches: PatchCollection[] = [];

  cachedBillLists.forEach(({ endpoint, params }) => {
    switch (endpoint) {
      case 'searchBills':
        patches.push(
          dispatch(
            billAPI.util.updateQueryData(
              'searchBills',
              params as BillSearchParams,
              (draft) => {
                draft.data.forEach((bill) => updateBillRecipe(bill));
              },
            ),
          ),
        );
        break;
      case 'getBill':
        patches.push(
          dispatch(
            billAPI.util.updateQueryData('getBill', params, (draft) => {
              updateBillRecipe(draft);
            }),
          ),
        );
        break;
      case 'getTrackedBills':
        patches.push(
          dispatch(
            billAPI.util.updateQueryData('getTrackedBills', params, (draft) => {
              draft.forEach((bill) => updateBillRecipe(bill));
            }),
          ),
        );
        break;
      case 'getTaggedBills':
        patches.push(
          dispatch(
            billAPI.util.updateQueryData('getTaggedBills', params, (draft) => {
              draft.data.forEach((bill) => updateBillRecipe(bill));
            }),
          ),
        );
        break;
      case 'getSponsoredBills':
        patches.push(
          dispatch(
            billAPI.util.updateQueryData('getSponsoredBills', params, (draft) => {
              draft.data.forEach((bill) => updateBillRecipe(bill));
            }),
          ),
        );
        break;
      // no default
    }
  });
  return patches;
};

// If an api call modifies a bill, use the above function in #onQueryStarted to
// provide an billUpdateRecipe and apply optimistic updates across cached bills.

const billAPI = base.injectEndpoints({
  endpoints: (build) => ({
    searchBills: build.query<BillSearchResult, BillSearchParams>({
      query: (args) => ({
        url: `/bills?${convertParamsToQuery(args)}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: ApiTag.BILL_TAG,
                id,
              })),
              { type: ApiTag.BILL_TAG, id: SEARCH_BILL_LIST },
              { type: ApiTag.BILL_TAG, id: BILL_LISTS_ID },
            ]
          : [{ type: ApiTag.BILL_TAG, id: SEARCH_BILL_LIST }],
      onCacheEntryAdded: updateBillListCacheKey,
    }),
    searchBillsByBillNumber: build.query<
      BillSearchResult,
      { jurisdictionAbbr: string; searchText: string }
    >({
      query: ({ jurisdictionAbbr, searchText }) => ({
        url: `/jurisdictions/${jurisdictionAbbr.toLowerCase()}/bills/by-number/${searchText}`,
        method: 'GET',
      }),
    }),
    getBill: build.query<BillDetailed, { billId: string; teamId?: number }>({
      query: ({ billId, teamId }) => ({
        url: `/bills/${billId}?teamId=${teamId}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: ApiTag.BILL_TAG, id: result?.id }],
      onCacheEntryAdded: updateBillListCacheKey,
    }),
    trackBill: build.mutation<void, { bill: Bill; teamIds: number[] }>({
      query: (data) => ({
        url: '/team/tracked-bills',
        method: 'POST',
        data: { billId: data.bill.id, teamIds: data.teamIds },
      }),
      onQueryStarted: ({ bill, teamIds }, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.trackBillTracked(bill, teamIds, state.account.organizationUser);
        const { data: teamMemberships } =
          TeamAPI.endpoints.getUsersTeamMemberships.select(undefined)(getState());
        const teams = (teamMemberships || []).reduce(
          (list: Team[], membership: TeamMembership) => {
            if (teamIds.includes(membership.teamId) && membership.team)
              return list.concat(membership.team);
            return list;
          },
          [],
        );
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (bill.id === b.id) {
            if (!b.teams) b.teams = [];
            b.teams.push(...teams);
          }
        });
        let patchResult: PatchCollection;
        if (!bill.teams) {
          // Add bill to tracked bills list if not already present
          patchResult = dispatch(
            billAPI.util.updateQueryData('getTrackedBills', undefined, (draft) => {
              const copyBill = assign({}, bill);
              Object.assign(copyBill, { teams });
              draft.push(copyBill);
            }),
          );
        }
        queryFulfilled.catch(() => {
          patchResult?.undo();
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: (response, error, arg) => [
        { type: ApiTag.BILL_TAG, id: arg.bill.id },
        { type: ApiTag.BILL_TAG, id: TRACKED_BILL_LIST_ID },
        ApiTag.BILL_TAG_LIMIT,
      ],
    }),
    bulkTrackBills: build.mutation<void, { bills: Bill[]; teamId: number }>({
      query: (data) => ({
        url: `/teams/${data.teamId}/bulk-tracked-bills`,
        method: 'PUT',
        data: { billIds: data.bills.map((bill) => bill.id) },
      }),
      onQueryStarted: ({ bills, teamId }, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.trackBulkBillsTracked(
          bills,
          teamId,
          state.account.organizationUser,
        );

        const { data: teamMemberships } =
          TeamAPI.endpoints.getUsersTeamMemberships.select(undefined)(getState());
        const teams = (teamMemberships || []).reduce(
          (list: Team[], membership: TeamMembership) => {
            if (teamId == membership.teamId && membership.team)
              return list.concat(membership.team);
            return list;
          },
          [],
        );
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (bills.map((bill) => bill.id).includes(b.id)) {
            if (!b.teams) b.teams = [];
            b.teams.push(...teams);
          }
        });
        let patchResult: PatchCollection;
        bills.map((bill) => {
          if (!bill.teams) {
            patchResult = dispatch(
              billAPI.util.updateQueryData('getTrackedBills', undefined, (draft) => {
                const copyBill = assign({}, bill);
                Object.assign(copyBill, { teams });
                draft.push(copyBill);
              }),
            );
          }
        });
        queryFulfilled.catch(() => {
          patchResult?.undo();
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: () => [
        { type: ApiTag.BILL_TAG, id: TRACKED_BILL_LIST_ID },
        ApiTag.BILL_TAG_LIMIT,
      ],
    }),
    untrackBill: build.mutation<
      void,
      { bill: BillDetailed | BillSummary; teamIds: number[] }
    >({
      query: (arg) => ({
        url: `/team/tracked-bills/${arg.bill.id}?teamIds=${arg.teamIds.join(',')}`,
        method: 'DELETE',
      }),
      invalidatesTags: (response, error, arg) => [
        { type: ApiTag.BILL_TAG, id: arg.bill.id },
        ApiTag.BILL_TAG_LIMIT,
      ],
      onQueryStarted: ({ bill, teamIds }, { getState, dispatch, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.trackBillUntracked(bill, teamIds, state.account.organizationUser);
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (b.id === bill.id && b.teams) {
            b.teams = b.teams.filter(({ id }) => !teamIds.includes(id));
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
    }),
    bulkUntrackBills: build.mutation<void, { bills: Bill[]; teamId: number }>({
      query: (data) => ({
        url: `/teams/${data.teamId}/bulk-tracked-bills`,
        method: 'DELETE',
        data: { billIds: data.bills.map((bill) => bill.id) },
      }),
      onQueryStarted: ({ bills, teamId }, { getState, dispatch, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.trackBulkBillsUntracked(
          bills,
          teamId,
          state.account.organizationUser,
        );
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (bills.map((bill) => bill.id).includes(b.id) && b.teams) {
            b.teams = b.teams.filter(({ id }) => teamId !== id);
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: [ApiTag.BILL_TAG_LIMIT],
    }),
    getTrackedBills: build.query<Bill[], undefined>({
      query: () => ({
        url: '/organization-user/tracked-bills',
        method: 'GET',
      }),
      transformResponse: (response: { data: Bill[] }) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ApiTag.BILL_TAG,
                id,
              })),
              { type: ApiTag.BILL_TAG, id: TRACKED_BILL_LIST_ID },
              { type: ApiTag.BILL_TAG, id: BILL_LISTS_ID },
            ]
          : [{ type: ApiTag.BILL_TAG, id: TRACKED_BILL_LIST_ID }],
      onCacheEntryAdded: updateBillListCacheKey,
    }),
    getTrackedBillsLimit: build.query<TrackedBillsLimit, number[]>({
      query: (teamIds) => {
        const queryString = teamIds.map((id) => `teamIds=${id}`).join('&');
        return {
          url: `/team/tracked-bills?${queryString}`,
          method: 'GET',
        };
      },
      providesTags: [ApiTag.BILL_TAG_LIMIT],
    }),
    tagBill: build.mutation<void, { bill: Bill; tag: Tag }>({
      query: ({ bill, tag }) => ({
        url: `/organization-user/tagged-bills`,
        method: 'POST',
        data: { billId: bill.id, tagId: tag.id },
      }),
      onQueryStarted({ bill, tag }, { getState, dispatch, queryFulfilled }) {
        const state = getState() as unknown as State;
        BillAnalytics.trackBillTagged(tag, bill, state.account.organizationUser);
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (b.id === bill.id) {
            b.tags = b.tags.concat(tag).sort((a, b) => (a.id < b.id ? -1 : 1));
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: (response, error, { bill, tag }) => [
        { type: ApiTag.BILL_TAG, id: bill.id },
        { type: ApiTag.BILL_TAG, id: TAGGED_BILL_LIST_ID },
        { type: ApiTag.TAG_TAG, id: tag.id },
      ],
    }),
    bulkTagBills: build.mutation<void, { bills: Bill[]; tag: Tag }>({
      query: ({ bills, tag }) => ({
        url: `/teams/bulk-tagged-bills/${tag.id}`,
        method: 'POST',
        data: { billIds: bills.map((bill) => bill.id), tagId: tag.id },
      }),
      onQueryStarted({ bills, tag }, { getState, dispatch, queryFulfilled }) {
        const state = getState() as unknown as State;
        BillAnalytics.trackBulkBillsTagged(bills, tag, state.account.organizationUser);
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (bills.map((bill) => bill.id).includes(b.id)) {
            b.tags = b.tags.concat(tag).sort((a, b) => (a.id < b.id ? -1 : 1));
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: (response, error, { tag }) => [
        { type: ApiTag.BILL_TAG, id: TAGGED_BILL_LIST_ID },
        { type: ApiTag.TAG_TAG, id: tag.id },
      ],
    }),
    untagBill: build.mutation<void, { bill: Bill; tag: Tag }>({
      query: ({ bill, tag }) => ({
        url: `/organization-user/tagged-bills/${tag.id}?billId=${bill.id}`,
        method: 'DELETE',
        data: { billId: bill.id, tagId: tag.id },
      }),
      onQueryStarted: ({ bill, tag }, { getState, dispatch, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.trackBillUntagged(tag, bill, state.account.organizationUser);
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (b.id === bill.id) {
            b.tags = b.tags.filter((t) => t.id !== tag.id);
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: (response, error, { bill, tag }) => [
        { type: ApiTag.BILL_TAG, id: bill.id },
        { type: ApiTag.TAG_TAG, id: tag.id },
      ],
    }),
    bulkUntagBills: build.mutation<void, { bills: Bill[]; tag: Tag }>({
      query: ({ bills, tag }) => ({
        url: `/teams/bulk-tagged-bills/${tag.id}`,
        method: 'DELETE',
        data: { billIds: bills.map((bill) => bill.id), tagId: tag.id },
      }),
      onQueryStarted({ bills, tag }, { getState, dispatch, queryFulfilled }) {
        const state = getState() as unknown as State;
        BillAnalytics.trackBulkBillsUntagged(
          bills,
          tag,
          state.account.organizationUser,
        );
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (bills.map((bill) => bill.id).includes(b.id)) {
            b.tags = b.tags.filter((t) => t.id !== tag.id);
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
      invalidatesTags: (response, error, { tag }) => [
        { type: ApiTag.BILL_TAG, id: TAGGED_BILL_LIST_ID },
        { type: ApiTag.TAG_TAG, id: tag.id },
      ],
    }),
    getTaggedBills: build.query<
      { count: number; data: BillSummary[]; jurisdictions: string[] },
      { tagId: number; searchParams?: BillSearchParams }
    >({
      query: ({ tagId, searchParams }) => ({
        url: `/organization-user/tagged-bills/${tagId}${
          searchParams ? `?${convertParamsToQuery(searchParams)}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: ApiTag.BILL_TAG, id }) as const),
              { type: ApiTag.BILL_TAG, id: TAGGED_BILL_LIST_ID },
              { type: ApiTag.BILL_TAG, id: BILL_LISTS_ID },
            ]
          : [{ type: ApiTag.BILL_TAG, id: TAGGED_BILL_LIST_ID }],
      onCacheEntryAdded: updateBillListCacheKey,
    }),
    getSponsoredBills: build.query<
      { count: number; data: BillSummary[] },
      { personId: number; searchParams: BillSearchParams }
    >({
      query: ({ personId, searchParams }) => ({
        url: `/bills/sponsored-bills/${personId}?${convertParamsToQuery(searchParams)}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: ApiTag.BILL_TAG, id })),
              { type: ApiTag.BILL_TAG, id: SPONSORED_BILL_LIST_ID },
              { type: ApiTag.BILL_TAG, id: BILL_LISTS_ID },
            ]
          : [{ type: ApiTag.BILL_TAG, id: SPONSORED_BILL_LIST_ID }],
      onCacheEntryAdded: updateBillListCacheKey,
    }),
    setBillAlias: build.mutation<
      void,
      { bill: Bill; alias: Partial<BillAlias> | Partial<BillAlias>[]; reset?: boolean }
    >({
      query: ({ alias, reset = false }) => {
        const aliasArray = Array.isArray(alias) ? alias : [alias];
        return {
          url: `/bill-alias?reset=${reset}`,
          method: 'POST',
          data: aliasArray.map((a) => ({
            id: a.id,
            billId: a.billId,
            teamId: a.teamId,
            alias: a.alias,
          })),
        };
      },
      invalidatesTags: (response, error, { bill }) => [
        { type: ApiTag.BILL_TAG, id: bill.id },
      ],
      onQueryStarted: async (
        { bill, alias },
        { getState, dispatch, queryFulfilled },
      ) => {
        const state = getState() as unknown as State;
        const aliasArray = Array.isArray(alias) ? alias : [alias];

        aliasArray.forEach((a) => {
          BillAnalytics.addBillAlias(bill, a, state.account.organizationUser);
        });

        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (b.id === bill.id) {
            aliasArray.forEach((a) => {
              b.userAliases.push(a as BillAlias);
            });
          }
        });

        try {
          await queryFulfilled;
        } catch (err) {
          console.log('ERROR UPDATING PATCHES', err);
          patches.forEach((p) => {
            p.undo();
          });
        }
      },
    }),
    clearBillAlias: build.mutation<void, { bill: Bill; alias: BillAlias }>({
      query: ({ alias }) => ({
        url: `/bill-alias/${alias.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (response, error, { bill }) => [
        { type: ApiTag.BILL_TAG, id: bill.id },
      ],
      onQueryStarted: ({ bill, alias }, { getState, dispatch, queryFulfilled }) => {
        const state = getState() as unknown as State;
        BillAnalytics.deleteBillAlias(bill, alias, state.account.organizationUser);
        const patches = updateCachedBillLists(state, dispatch, (b) => {
          if (b.id === bill.id) {
            b.userAliases = b.userAliases.filter((a) => a.id !== alias.id);
          }
        });
        queryFulfilled.catch(() => {
          patches.forEach((p) => {
            p.undo();
          });
        });
      },
    }),
    getBillAutoSummary: build.query<BillAutoSummaryResponse, BillAutoSummaryParams>({
      query: ({ billId, billVersionId }) => ({
        url: `/bills/${billId}/versions/${billVersionId}/auto-summary`,
        method: 'GET',
      }),
    }),
    getBillAutoSummaryDemonstration: build.query<
      BillAutoSummaryResponse,
      BillAutoSummaryParams
    >({
      query: ({ billId, billVersionId }) => ({
        url: `/bills/${billId}/versions/${billVersionId}/demonstration-auto-summary`,
        method: 'GET',
      }),
    }),
    getBillSummariesGenerated: build.query<
      BillAISummariesGeneratedResponse,
      { billId: string }
    >({
      query: ({ billId }) => ({
        url: `/bills/${billId}/summaries-generated`,
        method: 'GET',
      }),
    }),
    getBillVersionToVersionAutoSummary: build.query<
      BillVersionToVersionAutoSummaryResponse,
      BillVersionToVersionAutoSummaryParams
    >({
      query: ({ billId, billVersionAId, billVersionBId }) => ({
        url: `/bills/${billId}/version-to-version-summary`,
        method: 'GET',
        params: {
          previousBillVersionId: billVersionAId,
          newBillVersionId: billVersionBId,
        },
      }),
    }),
    getSimilarBills: build.mutation<SimilarBillResult[], SimilarBillsParams>({
      queryFn: async (
        { bill, versionId, jurisdictions, searchTypes },
        api,
        options,
        baseQuery,
      ) => {
        const queries = searchTypes.map((type) => {
          return queryString.stringify({
            jurisdictions,
            sessions: ['current'],
            excludeOmnibus: true,
            similarToBillVersion: versionId,
            [type]: true,
          });
        });
        const results = await Promise.all(
          queries.map(async (query) => {
            BillAnalytics.trackGlobalBillSearched(
              versionId,
              query,
              (api.getState() as State).teamMode.teamId,
              bill,
              (api.getState() as State).account.organizationUser,
            );
            const response = await baseQuery({
              url: `/bills-fulltext?${query}`,
              method: 'GET',
            });
            if (!response.data) return undefined;
            return {
              meta: queryString.parse(query),
              result: response.data,
            };
          }),
        );
        return {
          data: results.filter((r) => r !== undefined) as SimilarBillResult[],
        };
      },
    }),
    emailBill: build.mutation<
      void,
      {
        data: {
          billId: string;
          emails: string[];
          billTextInfo: Record<string, unknown>;
        };
        bill: BillDetailed | BillSummary;
      }
    >({
      query: ({ data }) => ({ url: '/mail/bills/share', method: 'POST', data }),
      onQueryStarted: ({ data, bill }, { getState }) => {
        const { teamId } = (getState() as unknown as State).teamMode;
        const orgUser = (getState() as unknown as State).account.organizationUser;
        BillAnalytics.trackBillShared(data.emails, bill, teamId, orgUser);
      },
    }),
    getBillSourceAvailability: build.query<void, { billId: string; versionId: number }>(
      {
        query: ({ billId, versionId }) => ({
          url: `/bills/${billId}/versions/${versionId}/source-document`,
          method: 'GET',
        }),
      },
    ),
    getInsightsPolicyTopics: build.query<InsightsPolicyTopic[], object>({
      query: () => ({
        url: `/insight-policy-topics`,
        method: 'GET',
      }),
    }),
    getEndOfSessionReport: build.query<EndOfSessionReport, EndOfSessionReportParams>({
      query: (args) => ({
        url: `/eosr?${convertEOSRParamsToQuery(args)}`,
        method: 'GET',
      }),
    }),
    getPositionStatement: build.query<PositionStatement, GetPositionParams>({
      query: ({ billId, orgId }) => ({
        url: `/v2/position-statement/${billId}/${orgId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { billId, orgId }) =>
        result ? [{ type: ApiTag.POSITION_STATEMENT, id: `${billId}-${orgId}` }] : [],
    }),
    createPositionStatement: build.mutation<PositionStatement, CreatePositionDto>({
      query: (newPosition) => ({
        url: `/v2/position-statement`,
        method: 'POST',
        data: newPosition,
      }),
      invalidatesTags: (response, error, arg) => [
        { type: ApiTag.BILL_TAG, id: arg.billId },
        { type: ApiTag.BILL_TAG, id: SEARCH_BILL_LIST },
      ],
    }),
    updatePositionStatement: build.mutation<PositionStatement, UpdatePositionParams>({
      query: ({ id, updateData }) => ({
        url: `/v2/position-statement/${id}`,
        method: 'PUT',
        data: updateData,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: ApiTag.POSITION_STATEMENT,
                id: `${result.billId}-${result.orgId}`,
              },
              { type: ApiTag.BILL_TAG, id: result.billId },
              { type: ApiTag.BILL_TAG, id: SEARCH_BILL_LIST },
            ]
          : [],
    }),
    deletePositionStatement: build.mutation<PositionStatement, { id: number }>({
      query: ({ id }) => ({
        url: `/v2/position-statement/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: ApiTag.POSITION_STATEMENT,
                id: `${result.billId}-${result.orgId}`,
              },
              { type: ApiTag.BILL_TAG, id: result.billId },
              { type: ApiTag.BILL_TAG, id: SEARCH_BILL_LIST },
            ]
          : [],
    }),
  }),
  overrideExisting: false,
});

export default billAPI;
