import {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  MutableRefObject,
} from 'react';
import CloseIcon from '../../../components/svg/CloseIcon';
import DropDownButton from '../Buttons/DropDownButton';
import TextButton from '../Buttons/TextButton';
import './DropDown.scss';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SingleSelectDropDown from './SingleSelectDropDown';

export type OptionType<T> = {
  value: T;
  label: string;
  section?: SectionHeader;
  key?: string;
  isDisabled?: boolean;
};

export type SectionHeader = string;

type MultipleSelectDropDownProps<T> = {
  title: string;
  currentSelectedValues: T[];
  options: OptionType<T>[];
  alignRight: boolean;
  sections?: SectionHeader[];
  onChange: (selectedOptions: T[]) => void;
  equals: (a: T, b: T) => boolean;
  description?: string;
  columnCount?: number;
  id?: string;
  useTagsAsLabel?: boolean;
  hideCount?: boolean;
  floatSelectedToTop?: boolean;
  search?: boolean;
  advancedFilterOptions?: OptionType<string>[];
  onFilterOptionsChange?: (option: OptionType<string>) => void;
  defaultFilterOption?: string;
  selectedFilterOption?: string;
  height?: string;
  clearAllRef?: MutableRefObject<any>;
};

const DESELECT_ALL = 'Clear All';

const MultipleSelectDropDown = <T extends any>(
  props: MultipleSelectDropDownProps<T>,
): ReactElement => {
  const {
    title,
    currentSelectedValues,
    options,
    alignRight,
    sections,
    onChange,
    equals,
    description,
    columnCount,
    id,
    useTagsAsLabel,
    hideCount,
    advancedFilterOptions,
    defaultFilterOption,
    selectedFilterOption,
    onFilterOptionsChange,
    clearAllRef,
    height = '200px',
    floatSelectedToTop = false,
    search = false,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [displayed, setDisplayed] = useState(false);
  const [selectedValues, setSelectedValues] = useState<T[]>(currentSelectedValues);
  const [filterCount, setFilterCount] = useState(currentSelectedValues?.length ?? 0);
  const [showTooltipGate, setShowTooltipGate] = useState<boolean>(false);
  const { t } = useTranslation();
  const showClass = displayed ? ' show' : '';
  const [searchQuery, setSearchQuery] = useState('');

  const clearAllFilters = (disabled: boolean) => {
    setDisplayed(false);
    if (!disabled) {
      setSelectedValues([]);
      onChange([]);
      setFilterCount(0);
      if (onFilterOptionsChange && advancedFilterOptions)
        onFilterOptionsChange(advancedFilterOptions[0]);
    }
  };

  useEffect(() => {
    if (clearAllRef) {
      clearAllRef.current = clearAllFilters;
    }
  }, [clearAllRef]);

  const searchBar = (
    <div className="search-bar mt-4">
      <FontAwesomeIcon className="search-icon" icon={faSearch} />
      <input
        className="tag-search-input"
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search tags"
        type="text"
        value={searchQuery}
      />
    </div>
  );

  // Filter options based on search query
  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options;
    return options?.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [options, searchQuery]);

  const selectedOptions = useMemo(
    () => options?.filter((option) => selectedValues?.includes(option.value)),
    [options, selectedValues],
  );

  const tagsLabel = useMemo(
    () =>
      selectedOptions.length > 0 && useTagsAsLabel ? (
        <>
          {selectedOptions.map((o) => (
            <span key={o.label}>{o.label}</span>
          ))}
        </>
      ) : undefined,
    [selectedOptions, useTagsAsLabel],
  );

  const sortedOptions = useMemo(() => {
    if (!floatSelectedToTop) return filteredOptions;

    const selected = filteredOptions.filter((option) =>
      selectedValues.map(String).includes(String(option.value)),
    );
    const notSelected = filteredOptions.filter(
      (option) => !selectedValues.map(String).includes(String(option.value)),
    );
    return [...selected, ...notSelected];
  }, [filteredOptions, selectedValues, floatSelectedToTop, currentSelectedValues]);

  const handleClickOutside = useCallback((e: MouseEvent): void => {
    if (ref.current && ref.current.contains(e.target as Element)) {
      return;
    }
    setDisplayed(false);
    setShowTooltipGate(false);
  }, []);

  useEffect(() => {
    if (displayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setSelectedValues(currentSelectedValues);
      setShowTooltipGate(false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayed, handleClickOutside, currentSelectedValues]);

  useEffect(() => {
    setSelectedValues(currentSelectedValues);
  }, [currentSelectedValues]);

  const onSelectionChange = (option: OptionType<T>, selected: boolean): void => {
    let newSelectedValues: T[] = selectedValues;

    if (selected && option.isDisabled) {
      setShowTooltipGate(true);
    } else if (selected) {
      newSelectedValues = [...newSelectedValues, option.value];
      setShowTooltipGate(false);
    } else {
      newSelectedValues = selectedValues
        .map(String)
        .filter((v) => !equals(v as T, String(option.value) as T)) as T[];
      setShowTooltipGate(false);
    }
    setSelectedValues(newSelectedValues);
  };

  const renderClearAllFilters = (disabled: boolean) => (
    <TextButton
      className="bold clear-selection"
      label={DESELECT_ALL}
      onClick={() => clearAllFilters(disabled)}
    />
  );

  const renderOption = (option: OptionType<T>, index: number): ReactElement => {
    const checked = !!selectedValues
      .map(String)
      .find((v) => equals(v as T, String(option.value) as T));
    const optionId = `dropdown-multi-${index}-${option.key || option.label}`.replace(
      /\s/g,
      '-',
    );

    return (
      <div
        className={
          option.isDisabled
            ? 'dropdown-multi-item disabled-option'
            : 'dropdown-multi-item'
        }
        key={optionId}
        onClick={() => onSelectionChange(option, !checked)}
      >
        <input checked={checked} id={optionId} readOnly type="checkbox" />
        <label>{option.label}</label>
      </div>
    );
  };

  const renderOptions = useCallback(
    (optionsToRender: OptionType<T>[]): ReactElement[] =>
      optionsToRender.map((option, index) => renderOption(option, index)),
    [selectedValues, equals, onSelectionChange],
  );

  const renderOptionsWithSections = useCallback((): ReactElement => {
    const optionsWithoutSections: OptionType<T>[] = [];
    const sectionOptionMap: { [label: string]: OptionType<T>[] } = {};

    sections?.forEach((section) => {
      sectionOptionMap[section] = [];
    });

    sortedOptions.forEach((option) => {
      if (!option.section || !sectionOptionMap[option.section]) {
        optionsWithoutSections.push(option);
      } else {
        sectionOptionMap[option.section].push(option);
      }
    });

    return (
      <>
        {renderOptions(optionsWithoutSections)}
        {sections?.map((section) => (
          <>
            <div className="section-header" key={section}>
              {section}
            </div>
            {renderOptions(sectionOptionMap[section])}
          </>
        ))}
      </>
    );
  }, [sections, sortedOptions, renderOptions]);

  const saveSelection = (): void => {
    onChange(selectedValues);
    setFilterCount(selectedValues.length);
    setDisplayed(false);
  };

  const alignClass = alignRight ? ' dropdown-menu-right' : '';
  return (
    <div className={`dropdown${showClass}`} ref={ref}>
      <DropDownButton
        dropdownDisplayed={displayed}
        id={id}
        label={title}
        onClick={() => setDisplayed(!displayed)}
        selectedCount={hideCount ? undefined : filterCount}
        tagsLabel={tagsLabel}
      />
      <div
        aria-labelledby="dropdownMenuButton"
        className={`dropdown-menu multi-select${alignClass}${showClass}`}
      >
        <div className="flex-row d-flex">
          <div className="title">{title}</div>
          <CloseIcon
            className="align-self-center"
            onClick={() => {
              setDisplayed(false);
            }}
          />
        </div>
        {description ? <div className="description">{description}</div> : ''}
        {search && searchBar}
        {advancedFilterOptions && onFilterOptionsChange && (
          <div className="mb-4">
            <SingleSelectDropDown
              defaultLabel={defaultFilterOption ?? advancedFilterOptions[0]?.label}
              id="adv-select-filter-dropdown"
              onSelect={onFilterOptionsChange}
              options={advancedFilterOptions}
              selectedValue={selectedFilterOption}
            />
          </div>
        )}

        <div
          className="flex-column"
          id="popover-anchor"
          style={{ columnCount, maxHeight: height, overflowY: 'auto' }}
        >
          {!sections && renderOptions(sortedOptions)}
          {sections && renderOptionsWithSections()}
        </div>
        <div className="flex-row dropdown-divider" />
        <div className="dropdown-footer flex-row" style={{ whiteSpace: 'nowrap' }}>
          <button
            className="btn btn-action dropdown-save-button"
            id={`dropdown-save-button-${title.replace(/ /g, '-')}`}
            onClick={saveSelection}
            type="button"
          >
            Save
          </button>
          {renderClearAllFilters(!selectedValues || selectedValues.length === 0)}
        </div>
      </div>
      {showTooltipGate && displayed && (
        <GateTooltip
          accountType="pro"
          anchorId={'popover-anchor'}
          customContent={
            <Trans
              components={{
                tagLink: <a href={t('urls.viewHearings')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.viewHearings"
              key="featureGating.tooltipText.viewHearings"
            />
          }
          featureName={GateTooltipFeature.ViewHearings}
          isOpen={showTooltipGate}
          key={'popover-anchor-show'}
          place="bottom"
        />
      )}
    </div>
  );
};

MultipleSelectDropDown.defaultProps = {
  alignRight: false,
  equals: (a: any, b: any) => a === b,
  floatSelectedToTop: false,
  search: false,
};

export default MultipleSelectDropDown;
