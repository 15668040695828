/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { SortBy, SortOrder } from '../models/SortOrder';
import { BillSponsor, BillDetailed, Bill } from '@enview/interface/types/bills/Bill';
import { OptionType } from '../scenes/Shared/DropDowns/SingleSelectDropDown';

export const getJurisdiction = (bill: {
  session: { state: { abbreviation: string } };
}) => {
  const jurisdiction =
    bill.session.state.abbreviation === 'usa'
      ? 'Federal'
      : bill.session.state.abbreviation.toUpperCase();
  return jurisdiction;
};

const getLastUpdatedTime = (bill: any) => {
  const actionDates = bill.actions
    ? bill.actions.map((action: any) => action.actionDate)
    : [bill.updatedAt];
  const annotationDates = bill.annotations
    ? bill.annotations.map((annotation: any) => annotation.createdAt)
    : [];
  const usersTrackingDates = bill.usersTracking
    ? bill.usersTracking.map((usersTracking: any) => usersTracking.startedAt)
    : [];
  const dates = [...actionDates, ...annotationDates, ...usersTrackingDates].filter(
    (date) => date !== undefined,
  );
  return Math.max(...dates.map((time) => parseInt(moment(time).format('x'), 10)));
};

const sortBillByRecentlyUpdated = (bills: Bill[], isReversed: boolean) => {
  if (!bills.length) {
    return [];
  }

  const billWithMostRecentUpdate = bills.map((bill) => {
    const latestUpdatedTime = getLastUpdatedTime(bill);
    return {
      ...bill,
      latestUpdatedTime,
    };
  });

  if (isReversed) {
    return billWithMostRecentUpdate.sort((a, b) => {
      if (a.latestUpdatedTime < b.latestUpdatedTime) return -1;
      if (a.latestUpdatedTime > b.latestUpdatedTime) return 1;
      return 0;
    });
  }
  return billWithMostRecentUpdate.sort((a, b) => {
    if (a.latestUpdatedTime < b.latestUpdatedTime) return 1;
    if (a.latestUpdatedTime > b.latestUpdatedTime) return -1;
    return 0;
  });
};

export const sortBillByAlpha = (bills: BillDetailed[], isReversed: boolean) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return bills.sort((a, b) => {
    if (isReversed) {
      return collator.compare(
        `${getJurisdiction(b)}${b.alphanumSort}`,
        `${getJurisdiction(a)}${a.alphanumSort}`,
      );
    }
    return collator.compare(
      `${getJurisdiction(a)}${a.alphanumSort}`,
      `${getJurisdiction(b)}${b.alphanumSort}`,
    );
  });
};

export const sortBillsBySortLabel = (bills: Bill[], sortOrder: SortOrder) => {
  if (!sortOrder) {
    return sortBillByAlpha(bills as BillDetailed[], false);
  }
  const { sortBy, isReversed } = sortOrder;
  switch (sortBy) {
    case SortBy.RECENT:
      return sortBillByRecentlyUpdated(bills, isReversed);
    case SortBy.ALPHANUMERICAL:
    default:
      return sortBillByAlpha(bills as BillDetailed[], isReversed);
  }
};

export const sortArrayObjectByPropertyAsce = (object: any[], property: string) => {
  return object.sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
};

export const sortArrayObjectByPropertyDesc = (object: any[], property: string) => {
  return object.sort((a, b) => {
    if (a[property] > b[property]) return -1;
    if (a[property] < b[property]) return 1;
    return 0;
  });
};

export const copyElement = (elementId: string) => {
  const selection = window.getSelection();
  if (selection) {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId) as Node);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    return true;
  }
  return false;
};

export const partyString = (billSponsor: BillSponsor): string => {
  if (
    !billSponsor?.person?.electedPositions ||
    billSponsor.person.electedPositions.length === 0 ||
    !billSponsor.person.electedPositions[0]?.party
  ) {
    return '';
  }
  return ` (${billSponsor.person.electedPositions[0].party.slice(0, 1).toUpperCase()})`;
};

export const democratPartyIdentifier = ' (D)';
export const republicanPartyIdentifier = ' (R)';

enum AdvFilterValue {
  Any = 'any',
  All = 'all',
  None = 'none',
}

export const advancedFilterOptions: OptionType<AdvFilterValue>[] = [
  {
    label: 'Any of Selected',
    value: AdvFilterValue.Any,
  },
  {
    label: 'All of Selected',
    value: AdvFilterValue.All,
  },
  {
    label: 'None of Selected',
    value: AdvFilterValue.None,
  },
];
