import React, { MutableRefObject } from 'react';
import MultipleSelectDropDown, {
  OptionType,
} from '../DropDowns/MultipleSelectDropDown';
import { advancedFilterOptions } from '../../../utils';

interface TagNameFilterProps extends React.HTMLAttributes<HTMLDivElement> {
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  setSelectedTagFilterOption?: (option: string) => void;
  tagOptions: OptionType<any>[];
  selectedTagFilterOption?: string;
  clearAllRef?: MutableRefObject<any>;
}

export const TagNameFilter: React.FC<TagNameFilterProps> = (props) => {
  const {
    selectedTags,
    setSelectedTags,
    setSelectedTagFilterOption,
    tagOptions,
    selectedTagFilterOption,
    className,
    clearAllRef,
    ...divProps
  } = props;

  return (
    <div className={`filter tagnames ${className}`} {...divProps}>
      <MultipleSelectDropDown
        advancedFilterOptions={advancedFilterOptions}
        clearAllRef={clearAllRef}
        currentSelectedValues={selectedTags}
        floatSelectedToTop
        onChange={(selectedTagValues) => {
          setSelectedTags([...selectedTagValues]);
        }}
        onFilterOptionsChange={(option) => {
          if (!setSelectedTagFilterOption) {
            return;
          }
          setSelectedTagFilterOption(option.value);
        }}
        options={tagOptions}
        search
        selectedFilterOption={selectedTagFilterOption}
        title={`Tag Names`}
      />
    </div>
  );
};

export default TagNameFilter;
